import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import "./MetaBuyingDrafts.scss";
import MetaTableWidget from "./Components/MetaTableWidget";
import { BPMTable, Button, ButtonType, CheckBox, Header, ToggleSwitch } from "../Components";
import { useStateFunction } from "../utils/hooks/useData";
import { MdCheck, MdMoreVert, MdRemoveRedEye } from "react-icons/md";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { AdRow, AdSetRow, CampaignRow, MetaBuyingTableRow } from "./MetaBuying";
import * as R from "ramda";
import {
  useFilterBar,
  useGetButtonPosition,
  useSelectRow,
  useTableHeadersRenderer,
  useViewableDataMap,
} from "./MetaBuyingUtils";
import MoreActionsMenu from "./Components/MoreActionsMenu";
import { createPortal } from "react-dom";

interface MetaBuyingDraftsProps {
  isInternal: boolean;
  selectedAdAccount: {
    account_id: string;
    account_name: string;
    business_manager: string;
  };
  setSelectedAdAccount: React.Dispatch<
    React.SetStateAction<{
      account_id: string;
      account_name: string;
      business_manager: string;
    }>
  >;
  adAccountOptions: {
    account_id: string;
    account_name: string;
    business_manager: string;
  }[];
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}

const MetaBuyingDrafts = ({
  isInternal,
  selectedAdAccount,
  setSelectedAdAccount,
  adAccountOptions,
  campaignRows,
  adSetRows,
  adRows,
}: MetaBuyingDraftsProps & RouteComponentProps): JSX.Element => {
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [selectedLevel, setSelectedLevel] = useState<string>(isInternal ? "campaign" : "ad");
  const [selectedRows, setSelectedRows] = useState<
    Record<string, Record<string, MetaBuyingTableRow>>
  >({
    campaign: {},
    adset: {},
    ad: {},
  });
  const [selectAll, setSelectAll] = useState<Record<string, boolean>>({
    campaign: false,
    adset: false,
    ad: false,
  });

  // Map of each level to its rows. Data is filtered based on selected rows.
  const viewableDataMap: Record<string, MetaBuyingTableRow[]> = useViewableDataMap({
    campaignRows,
    adSetRows,
    adRows,
    selectedRows,
  });

  // Update selectAll state when viewableDataMap changes
  useEffect(() => {
    for (let level of Object.keys(selectedRows)) {
      const currObj = selectedRows[level];
      if (Object.keys(currObj).length === viewableDataMap[level].length) {
        setSelectAll(current => ({ ...current, [level]: true }));
      } else {
        setSelectAll(current => ({ ...current, [level]: false }));
      }
    }
  }, [selectedRows, viewableDataMap]);

  // Rows to display in the table based on selected level
  const draftsTableData: MetaBuyingTableRow[] = useMemo(() => {
    if (selectedLevel === "ad") {
      return viewableDataMap.ad;
    } else if (selectedLevel === "adset") {
      return viewableDataMap.adset;
    } else {
      return viewableDataMap.campaign;
    }
  }, [selectedLevel, viewableDataMap]);

  // Apply filter bar to table data
  const filteredData = useMemo(() => {
    return draftsTableData?.filter(filter) || [];
  }, [draftsTableData, filter]);

  const selectRow = useSelectRow({
    setSelectedRows,
    selectedLevel,
  });

  const buttonRefs = useRef({}); // Object to hold refs for each row
  const getButtonPosition = useGetButtonPosition({ buttonRefs, selectedLevel });
  const menuRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
  const [activeRowId, setActiveRowId] = useState<string>("");
  // Close menu if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        menuRef &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRefs.current[activeRowId]?.contains(event.target)
      ) {
        setActiveRowId("");
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeRowId]);

  const draftsTableHeaders: Header[] = useMemo(() => {
    let headers: Header[] = [
      {
        label: "",
        name: "id",
        width: 40,
        nonInteractive: true,
        renderer: (row: MetaBuyingTableRow) => {
          return (
            <CheckBox
              className="checkboxCell"
              checked={R.has(row.id, selectedRows[selectedLevel])}
              onCheck={() => selectRow(row)}
            />
          );
        },
      },
    ];

    if (!isInternal) {
      headers.push(
        ...[
          {
            label: "Ad",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="adNameCell highlighted">{row.name}</div>;
            },
          },
          {
            label: "Ad Set",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="adSetNameCell">{row.adset_name}</div>;
            },
          },
          {
            label: "Campaign",
            flex: 3,
            renderer: (row: AdRow) => {
              return <div className="campaignNameCell">{row.campaign_name}</div>;
            },
          },
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            width: 308,
            nonInteractive: true,
            renderer: (row: AdRow) => {
              return (
                <div className="actionsCell">
                  <Button
                    variant={ButtonFrameworkVariant.TRAILING_ICON}
                    icon={<MdRemoveRedEye />}
                    type={ButtonType.OUTLINED}
                    size="sm"
                  >
                    View Ad Preview
                  </Button>
                  <Button
                    className="approveButton"
                    variant={ButtonFrameworkVariant.TRAILING_ICON}
                    icon={<MdCheck />}
                    type={ButtonType.OUTLINED}
                    size="sm"
                  >
                    Approve
                  </Button>
                </div>
              );
            },
          },
        ]
      );
    } else {
      if (selectedLevel === "ad") {
        headers.push(
          ...[
            {
              label: "Ad",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adNameCell highlighted">{row.name}</div>;
              },
            },
            {
              label: "Ad Set",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="adSetNameCell">{row.adset_name}</div>;
              },
            },
            {
              label: "Campaign",
              flex: 4,
              renderer: (row: AdRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else if (selectedLevel === "adset") {
        headers.push(
          ...[
            {
              label: "Ad Set",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="adSetNameCell highlighted">{row.name}</div>;
              },
            },
            {
              label: "Campaign",
              flex: 4,
              renderer: (row: AdSetRow) => {
                return <div className="campaignNameCell">{row.campaign_name}</div>;
              },
            },
          ]
        );
      } else {
        headers.push({
          label: "Campaign",
          flex: 4,
          renderer: (row: CampaignRow) => {
            return <div className="campaignNameCell highlighted">{row.name}</div>;
          },
        });
      }

      headers.push(
        ...[
          {
            label: "Created On",
            name: "created",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="createdCell">{row.created}</div>;
            },
          },
          {
            label: "Creator",
            name: "lastuser",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return <div className="creatorCell">{row.lastuser}</div>;
            },
          },
        ]
      );

      if (selectedLevel === "ad") {
        headers.push(
          {
            label: "Client-Facing",
            flex: 2,
            renderer: (row: MetaBuyingTableRow) => {
              return (
                <div className="clientFacingCell">
                  <ToggleSwitch label={""} checked={false} onChange={() => {}} />
                </div>
              );
            },
          },
          {
            label: "Approval Status",
            width: 151,
            renderer: (row: MetaBuyingTableRow) => {
              return (
                <div className="approvalStatusCell">
                  <Button type={ButtonType.FILLED} design="secondary" size="sm">
                    Request Approval
                  </Button>
                </div>
              );
            },
          }
        );
      }

      headers.push({
        width: 247,
        nonInteractive: true,
        renderer: (row: MetaBuyingTableRow) => {
          const buttonPosition = getButtonPosition(row.id);
          return (
            <div className="actionsCell">
              <Button
                variant={ButtonFrameworkVariant.TRAILING_ICON}
                icon={
                  <img
                    src="https://s3-alpha-sig.figma.com/img/085a/2e1c/49e1a11602d1a971da4cf46a3e42a7d2?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g7vyXAJ68RFrDpD3O1RIQftjPkhiVEvDilJ06JDVVTOQN1tKH2srpxtB7acW-ZkfW3~u97Zg6ublBu8U4cotD4yAVBi6ugCZdABCj-v5l2PCACg8olbZBqG~3RoOqJiem3gz4-d20-0K-H~wsb1V5hTLtW~12H~m1G7ofZ5Bj~76Gw8OpoaDQQGMY8dqRyMrIZ-qOvbNzVYLasc6NLcIbU8C2xaDRuRHypaz4RjZ9oNnV2lgSleZpQSPhe5BOxYA~h9vSKjiTQqFur-gWQt2HYhp20cvWRupDBPJt4JzrgUFAkIYg7XoGqTViNyq17oRDDPFeF-D-qogrINn3KJwfA__"
                    alt=""
                  />
                }
                size="sm"
                type={ButtonType.FILLED}
              >
                Publish As Paused
              </Button>
              <div className="moreActionsContainer">
                <Button
                  ref={el => {
                    buttonRefs.current[row.id] = el;
                  }} // Assign ref for each button
                  variant={ButtonFrameworkVariant.ICON_ONLY}
                  type={ButtonType.FILLED}
                  icon={<MdMoreVert />}
                  size="sm"
                  onClick={() => {
                    setActiveRowId(row.id);
                  }}
                />
                {activeRowId === row.id &&
                  createPortal(
                    <div
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "white",
                        position: "fixed",
                        top: buttonPosition.top,
                        left: buttonPosition.left,
                        display: "flex",
                        zIndex: 1000,
                      }}
                      ref={menuRef}
                    >
                      <MoreActionsMenu
                        selectedRows={selectedRows}
                        selectedLevel={selectedLevel}
                        tab="drafts"
                      />
                    </div>,
                    document.body
                  )}
              </div>
            </div>
          );
        },
      });
    }

    return headers;
  }, [activeRowId, getButtonPosition, isInternal, selectRow, selectedLevel, selectedRows]);

  const draftsTableHeadersRenderer = useTableHeadersRenderer({
    filteredData,
    selectAll,
    setSelectAll,
    selectedRows,
    setSelectedRows,
    selectedLevel,
  });

  const draftsFilterBar = useFilterBar({
    pageTab: "drafts",
    tableData: draftsTableData,
    isInternal,
    selectedLevel,
    setFilter,
  });

  const draftsTable = useMemo(() => {
    return (
      <BPMTable
        className="draftsTableExternal"
        alternateColors={false}
        data={filteredData}
        headers={draftsTableHeaders}
        headersRenderer={draftsTableHeadersRenderer}
        filterBar={false}
        noRowsRenderer={() => <div>No rows to show.</div>}
      ></BPMTable>
    );
  }, [draftsTableHeaders, draftsTableHeadersRenderer, filteredData]);

  return (
    <div className="metaBuyingDrafts">
      <MetaTableWidget
        isInternal={isInternal}
        title="Drafts"
        selectedAdAccount={selectedAdAccount}
        setSelectedAdAccount={setSelectedAdAccount}
        adAccountOptions={adAccountOptions}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
        filterBar={draftsFilterBar}
        tableComponent={draftsTable}
        selectedRows={selectedRows}
      />
    </div>
  );
};

export default MetaBuyingDrafts;
