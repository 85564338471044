import React, { useMemo, useState, useEffect } from "react";
import { Page, FullPageSpinner } from "../Components";
import { RouteComponentProps, Router } from "@reach/router";
import { useTabbedNav } from "../utils/hooks/useNav";
import MetaBuyingCreate from "./MetaBuyingCreate/MetaBuyingCreate";
import MetaBuyingUpload from "./MetaBuyingUpload";
import MetaBuyingDrafts from "./MetaBuyingDrafts";
import MetaBuyingPublished from "./MetaBuyingPublished";
import "./MetaBuying.scss";
import { useSelector } from "react-redux";
import * as UserRedux from "../redux/user";
import { MetaBuyingProvider } from "./MetaBuyingContext";
import { awaitJSON, MetaLambdaFetch } from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";

const enum PageTab {
  CREATE = "create",
  UPLOAD = "upload",
  DRAFTS = "drafts",
  PUBLISHED = "published",
}

const NAVS = [
  { label: "Create", key: PageTab.CREATE },
  { label: "Upload", key: PageTab.UPLOAD },
  { label: "Drafts", key: PageTab.DRAFTS },
  { label: "Published", key: PageTab.PUBLISHED },
];

export type MetaBuyingTableRow = CampaignRow | AdSetRow | AdRow;

export interface CampaignRow {
  id: string;
  campaign_id: string;
  name: string;
  created: string;
  lastuser: string;
}

export interface AdSetRow {
  id: string;
  adset_id: string;
  name: string;
  campaign_id: string;
  campaign_name: string;
  created: string;
  lastuser: string;
}

export const enum ApprovalStatus {
  PRE_REVIEW = "PRE_REVIEW",
  IN_REVIEW = "IN_REVIEW",
  APPROVED = "APPROVED",
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
}

export interface AdRow {
  id: string;
  ad_id: string;
  name: string;
  adset_id: string;
  adset_name: string;
  campaign_id: string;
  campaign_name: string;
  client_facing: boolean;
  approval_status: ApprovalStatus;
  created: string;
  lastuser: string;
}

export const enum AdsManagerStatus {
  LIVE = "Live",
  PAUSED = "Paused",
  NONE = "None",
}

const MetaBuying = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "social/meta-buying",
    defaultKey: PageTab.CREATE,
  });
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const isInternal = useSelector(UserRedux.isInternalSelector);

  // TODO: Add lambda for fetching ad accounts from the database
  const [adAccountOptions, setAdAccountOptions] = useState<
    {
      account_id: string;
      account_name: string;
      business_manager: string;
    }[]
  >([]);
  const [selectedAdAccount, setSelectedAdAccount] = useState<{
    account_id: string;
    account_name: string;
    business_manager: string;
  }>({
    account_id: "",
    account_name: "",
    business_manager: "",
  });
  const [namingConventions, setNamingConventions] = useState<{
    campaign: string[];
    adset: string[];
    ad: string[];
  }>({
    campaign: [],
    adset: [],
    ad: [],
  });
  // const [granularity, setGranularity] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        let res = await MetaLambdaFetch("/getClientInfo", { params: { company: cid } });
        let data = await awaitJSON(res);
        // if (data.granularity) {
        //   setGranularity(data.granularity);
        // }
        if (data.namingConventions) {
          setNamingConventions(data.namingConventions);
        }
        if (data.accounts) {
          setAdAccountOptions(data.accounts);
        }
      } catch (e) {
        setError({ message: e.message, reportError: e });
      } finally {
        setLoading(false);
      }
    })();
  }, [cid, setError]);

  // TODO: Add lambda for fetching drafts and published rows from the database
  // useEffect(() => {}, []);
  const campaignRows: CampaignRow[] = useMemo(() => {
    return [
      {
        id: "1",
        campaign_id: "1",
        name: "Campaign 1",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "2",
        campaign_id: "2",
        name: "Campaign 2",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "3",
        campaign_id: "3",
        name: "Campaign 3",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-11-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
    ];
  }, []);
  const adSetRows: AdSetRow[] = useMemo(() => {
    return [
      {
        id: "1",
        adset_id: "1",
        name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "2",
        adset_id: "2",
        name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-10",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "3",
        adset_id: "3",
        name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "4",
        adset_id: "4",
        name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-10-10",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
    ];
  }, []);
  const adRows: AdRow[] = useMemo(() => {
    return [
      {
        id: "1",
        ad_id: "1",
        name: "Ad 1",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "2",
        ad_id: "2",
        name: "Ad 2",
        adset_id: "1",
        adset_name: "Ad Set 1",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "3",
        ad_id: "3",
        name: "Ad 3",
        adset_id: "2",
        adset_name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "4",
        ad_id: "4",
        name: "Ad 4",
        adset_id: "2",
        adset_name: "Ad Set 2",
        campaign_id: "1",
        campaign_name: "Campaign 1",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "5",
        ad_id: "5",
        name: "Ad 5",
        adset_id: "3",
        adset_name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "6",
        ad_id: "6",
        name: "Ad 6",
        adset_id: "3",
        adset_name: "Ad Set 3",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "7",
        ad_id: "7",
        name: "Ad 7",
        adset_id: "4",
        adset_name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
      {
        id: "8",
        ad_id: "8",
        name: "Ad 8",
        adset_id: "4",
        adset_name: "Ad Set 4",
        campaign_id: "2",
        campaign_name: "Campaign 2",
        client_facing: true,
        approval_status: ApprovalStatus.PRE_REVIEW,
        adsmanager_status: AdsManagerStatus.NONE,
        created: "2024-09-01",
        lastuser: "berto.garciacarrillo@tinuiti.com",
      },
    ];
  }, []);

  return (
    <MetaBuyingProvider>
      <Page
        app2Redesign
        title="Meta Buying"
        pageType="Meta Buying"
        navs={NAVS}
        selectedNav={tab}
        onNav={goToTab}
      >
        <div className="metaBuyingPage">
          {loading ? (
            <FullPageSpinner />
          ) : (
            <Router className="fullPageRouter">
              <MetaBuyingCreate
                default
                path={PageTab.CREATE}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                namingConventions={namingConventions}
                client={cid}
              />
              <MetaBuyingUpload path={PageTab.UPLOAD} />
              <MetaBuyingDrafts
                path={PageTab.DRAFTS}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={campaignRows}
                adSetRows={adSetRows}
                adRows={adRows}
              />
              <MetaBuyingPublished
                path={PageTab.PUBLISHED}
                isInternal={isInternal}
                selectedAdAccount={selectedAdAccount}
                setSelectedAdAccount={setSelectedAdAccount}
                adAccountOptions={adAccountOptions}
                campaignRows={campaignRows}
                adSetRows={adSetRows}
                adRows={adRows}
              />
            </Router>
          )}
        </div>
      </Page>
    </MetaBuyingProvider>
  );
});

export default MetaBuying;
