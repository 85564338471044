import "./SegmentationMapping.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useTabbedNav } from "../utils/hooks/useNav";
import { Page } from "../Components";
import SegmentationIdCreation from "./SegmentationIdCreation";
import SegmentationLabeling, { SegmentRow } from "./SegmentationLabeling";
import CustomSegments, {
  CommittedValue,
  RequiredSegmentNames,
  SystemGeneratedSegmentNames,
} from "./CustomSegments";
import { RouteComponentProps, Router } from "@reach/router";
import {
  MiscLambdaFetch,
  S3SignedUrlFetch,
  SegmentationMappingLambdaFetch,
  awaitJSON,
  pollS3,
} from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import Papa from "papaparse";

const enum PageTab {
  ID_GENERATION = "id-generation",
  LABELING = "labeling",
  CUSTOM_SEGMENTS = "custom-segments",
}

const NAVS = [
  { label: "ID Generation", key: PageTab.ID_GENERATION },
  { label: "Labeling", key: PageTab.LABELING },
  { label: "Custom Segments", key: PageTab.CUSTOM_SEGMENTS },
];

export interface CustomSegmentsData {
  segmentId: string;
  segmentName: string;
  values: CommittedValue[];
}

const SegmentationMapping = React.memo(({ navigate }: RouteComponentProps) => {
  const { tab, goToTab } = useTabbedNav({
    navigate,
    baseURL: "segmentation-mapping",
    defaultKey: PageTab.ID_GENERATION,
  });

  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [rawData, setRawData] = useState<SegmentRow[] | null>(null);
  const [dataGranularity, setDataGranularity] = useState<
    "ad" | "ad_group" | "campaign" | undefined
  >();

  const fetchRawData = useCallback(async () => {
    try {
      if (process.env.NODE_ENV === "development") {
        console.info("In development mode, fetching getRawPlatformData from non-comet endpoint");
        const res = await SegmentationMappingLambdaFetch("/getRawPlatformData", {
          params: {
            company: cid,
          },
        });
        const resData = await awaitJSON(res);
        const s3FetchRes = await S3SignedUrlFetch(resData);
        const s3ResText = await s3FetchRes.text();
        const { data } = Papa.parse(s3ResText, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });
        setRawData(data as SegmentRow[]);
      } else {
        const lambdaArgs = { company: cid };
        const result = await MiscLambdaFetch("/kickOffLambda", {
          method: "POST",
          body: {
            fileType: "txt",
            lambdaArgs,
            lambdaName: "segmentationmapping-getRawPlatformData",
          },
        });
        const uuid = await awaitJSON(result);
        const content = await pollS3({
          autoDownload: false,
          bucket: "bpm-cache",
          filename: `${uuid}.txt`,
          mimeType: "text/plain",
        });
        const textContent = await content.text();
        const lambdaResult = JSON.parse(textContent);

        if (lambdaResult.cometErrorMessage) {
          setError({ message: lambdaResult.cometErrorMessage });
        } else {
          setRawData(lambdaResult.data as SegmentRow[]);
        }
      }
    } catch (e) {
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [cid, setError]);

  // Fetch existing data at the main page level so that you don't need to wait for the load when you switch tabs.
  useEffect(() => {
    if (!rawData) {
      fetchRawData();
    }
  }, [cid, setError, rawData, fetchRawData]);

  const [customSegments, setCustomSegments] = useState<CustomSegmentsData[] | undefined>();

  // Get existing custom segments
  useEffect(() => {
    if (!customSegments) {
      (async () => {
        try {
          let res = await SegmentationMappingLambdaFetch("/getCustomSegments", {
            params: { company: cid },
          });
          const data = await awaitJSON(res);

          data.sort((a, b) => {
            // Ordering should be:
            // 1st - Channel
            // 2nd - Platform
            // 3rd - Campaign Ownership
            // 4th - Include in Fee Calc
            // Then all remaining values in alphabetical order.
            const segmentNameToRelativeValue = segName => {
              switch (segName) {
                case SystemGeneratedSegmentNames.CHANNEL:
                  return 1;
                case SystemGeneratedSegmentNames.PLATFORM:
                  return 2;
                case RequiredSegmentNames.CAMPAIGN_OWNERSHIP:
                  return 3;
                case RequiredSegmentNames.INCLUDE_IN_FEE_CALC:
                  return 4;
                default:
                  return 99;
              }
            };

            if (
              segmentNameToRelativeValue(a.segmentName) < segmentNameToRelativeValue(b.segmentName)
            ) {
              return -1;
            } else if (
              segmentNameToRelativeValue(a.segmentName) > segmentNameToRelativeValue(b.segmentName)
            ) {
              return 1;
            }

            return a.segmentName.localeCompare(b.segmentName);
          });

          data.forEach(seg => {
            seg.values.sort((a, b) => {
              if (a.valueName === "N/A") {
                return -1;
              } else if (b.valueName === "N/A") {
                return 1;
              }

              return a.valueName.localeCompare(b.valueName);
            });
          });

          setCustomSegments(data);
        } catch (e) {
          const reportError = e as Error;
          setError({ message: reportError.message, reportError });
        }
      })();
    }
  }, [cid, customSegments, setError]);

  const fetchDataGranularity = useCallback(async () => {
    try {
      const res = await SegmentationMappingLambdaFetch("/getGranularity", {
        params: {
          company: cid,
        },
      });
      const granularity = await awaitJSON(res);
      setDataGranularity(granularity);
    } catch (e: any) {
      setError({ message: e.message });
    }
  }, [cid, setError]);

  useEffect(() => {
    if (!dataGranularity) {
      fetchDataGranularity();
    }
  }, [dataGranularity, fetchDataGranularity]);

  return (
    <Page
      app2Redesign
      pageType="Segmentation Mapping"
      title="Segmentation Mapping"
      navs={NAVS}
      selectedNav={tab}
      onNav={goToTab}
    >
      <div className="segmentationMappingPage">
        <Router className="fullPageRouter">
          <SegmentationIdCreation
            default
            path={PageTab.ID_GENERATION}
            customSegments={customSegments}
          />
          <SegmentationLabeling
            path={PageTab.LABELING}
            data={rawData}
            fetchRawData={fetchRawData}
            customSegments={customSegments}
            dataGranularity={dataGranularity ?? "campaign"}
          />
          <CustomSegments
            data={customSegments}
            setData={setCustomSegments}
            path={PageTab.CUSTOM_SEGMENTS}
            dataGranularity={dataGranularity ?? "campaign"}
            setDataGranularity={setDataGranularity}
          />
        </Router>
      </div>
    </Page>
  );
});

export default SegmentationMapping;
