import { Metric } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { GlossaryItem } from "../SingleChannel/MetricsTable/metricsTableUtils";

const DELIVERY_METRICS = [Metric.CPM, Metric.IMPRESSIONS, Metric.SPEND];
const PERFORMANCE_METRICS = [
  Metric.CLICKS,
  Metric.CONVERSION_RATE,
  Metric.CPC,
  Metric.CPX,
  Metric.CTR,
  Metric.REVENUE,
  Metric.ROAS,
  Metric.VOLUME,
];

export const METRIC_TO_PRETTY_NAME = {
  [Metric.CLICKS]: "Clicks",
  [Metric.CONVERSION_RATE]: "Conversion Rate",
  [Metric.CPC]: "CPC",
  [Metric.CPM]: "CPM",
  [Metric.CPX]: "CPX",
  [Metric.CTR]: "CTR",
  [Metric.IMPRESSIONS]: "Impressions",
  [Metric.REVENUE]: "Revenue",
  [Metric.ROAS]: "ROAS",
  [Metric.SPEND]: "Spend",
  [Metric.VOLUME]: "KPI Volume",
} as const;

export const METRIC_OPTIONS = [
  { label: METRIC_TO_PRETTY_NAME[Metric.CLICKS], value: Metric.CLICKS },
  { label: METRIC_TO_PRETTY_NAME[Metric.CONVERSION_RATE], value: Metric.CONVERSION_RATE },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPC], value: Metric.CPC },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPM], value: Metric.CPM },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPX], value: Metric.CPX },
  { label: METRIC_TO_PRETTY_NAME[Metric.CTR], value: Metric.CTR },
  { label: METRIC_TO_PRETTY_NAME[Metric.IMPRESSIONS], value: Metric.IMPRESSIONS },
  { label: METRIC_TO_PRETTY_NAME[Metric.REVENUE], value: Metric.REVENUE },
  { label: METRIC_TO_PRETTY_NAME[Metric.ROAS], value: Metric.ROAS },
  { label: METRIC_TO_PRETTY_NAME[Metric.SPEND], value: Metric.SPEND },
  { label: METRIC_TO_PRETTY_NAME[Metric.VOLUME], value: Metric.VOLUME },
] as const;

// Only display delivery metrics in the Spark Charts and Compare Metrics of the Cross Channel Overview tab
export const OVERVIEW_TAB_METRIC_OPTIONS = [
  { label: METRIC_TO_PRETTY_NAME[Metric.CLICKS], value: Metric.CLICKS },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPC], value: Metric.CPC },
  { label: METRIC_TO_PRETTY_NAME[Metric.CPM], value: Metric.CPM },
  { label: METRIC_TO_PRETTY_NAME[Metric.CTR], value: Metric.CTR },
  { label: METRIC_TO_PRETTY_NAME[Metric.IMPRESSIONS], value: Metric.IMPRESSIONS },
  { label: METRIC_TO_PRETTY_NAME[Metric.SPEND], value: Metric.SPEND },
];

export const DELIVERY_METRIC_OPTIONS = METRIC_OPTIONS.filter(metric =>
  DELIVERY_METRICS.includes(metric.value)
);
export const PERFORMANCE_METRIC_OPTIONS = METRIC_OPTIONS.filter(metric =>
  PERFORMANCE_METRICS.includes(metric.value)
);

export const MEDIA_TYPE_TO_PRETTY_NAME = {
  audio: "Audio",
  display: "Display",
  streaming: "Streaming",
  tv: "Linear TV",
  social: "Social",
  search: "Search",
} as const;

export const GRAY_BAR_COLOR = "#E6E6E6";
export const GRAY_TEXT_COLOR = "#ADADAD";

export const DESCRIPTIVE_METRICS = [Metric.CPM, Metric.CLICKS, Metric.IMPRESSIONS, Metric.SPEND];

export const GLOSSARY: GlossaryItem[] = [
  {
    term: "Channel",
    definition: "This is a type of marketing effort (e.g. Paid Social).",
  },
  {
    term: "Platform",
    definition: "This is the system through which the ad impression is delivered (e.g. Meta).",
  },
  {
    term: "Surface",
    definition:
      "This is the end point where the ad is delivered to its audience (e.g. Facebook or Instagram). Note: surface isn't always applicable.",
  },
  {
    term: "Clicks",
    definition: "The number of individuals who click on the ad(s).",
  },
  {
    term: "Spend",
    definition: "The total dollars invested.	The number of dollars invested.",
  },
  {
    term: "Impressions",
    definition: "The number of times the ad(s) are delivered.",
  },
  {
    term: "KPI Volume",
    definition:
      "The number of outcome events generated (for the KPI selected in the dropdown at the top of the page).",
  },
  {
    term: "Revenue",
    definition: "The number of dollars generated in return.",
  },
  {
    term: "CPM",
    definition: "Cost per Thousand Impresisons = (spend x 1,000) / impressions",
  },
  {
    term: "ROAS",
    definition: "Return on Ad Spend = revenue / spend",
  },
  {
    term: "CPX",
    definition:
      "Cost per X = total spend / KPI volume (X is a placeholder letter for the KPI selected in the dropdown at the top of the page)",
  },
  {
    term: "CPC",
    definition: "Cost per Click = spend / clicks",
  },
  {
    term: "CTR",
    definition: "The percentage of impressions that result in a click.",
  },
  {
    term: "Conversion Rate",
    definition: "The percentage of impressions that result in a conversion.",
  },
];
