import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { AdRow, AdSetRow, CampaignRow, MetaBuyingTableRow } from "./MetaBuying";
import { CheckBox, FilterBar } from "../Components";

enum LEVEL_HEIRARCHY {
  campaign = 0,
  adset = 1,
  ad = 2,
}

interface SelectRowParams {
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectedLevel: string;
}

export const useSelectRow = ({
  setSelectedRows,
  selectedLevel,
}: SelectRowParams): ((row: MetaBuyingTableRow) => void) => {
  const updateDownstreamSelections = useCallback(
    (selections: Record<string, Record<string, MetaBuyingTableRow>>) => {
      if (!R.isEmpty(selections[selectedLevel])) {
        // If current level has no selections, no need to update downstream selections.
        // If current level has selections, update downstream selections to only include
        // rows that contain the id of rows in the current level.
        const selectedLevelObj = selections[selectedLevel];
        for (let i = LEVEL_HEIRARCHY[selectedLevel] + 1; i < 3; i++) {
          const subLevel = LEVEL_HEIRARCHY[i];
          const subLevelObj = selections[subLevel];
          for (let row of Object.values(subLevelObj)) {
            if (!R.has(row[`${selectedLevel}_id`], selectedLevelObj)) {
              delete subLevelObj[row.id];
            }
          }
        }
      }

      return selections;
    },
    [selectedLevel]
  );

  return useCallback(
    (row: MetaBuyingTableRow) => {
      const { id } = row;

      setSelectedRows(current => {
        let updatedSelections: Record<string, Record<string, MetaBuyingTableRow>>;

        if (R.has(id, current[selectedLevel])) {
          // Removing the row from selectedRows
          const currLevelObj = current[selectedLevel];
          const newObj = R.omit([id], currLevelObj);
          updatedSelections = { ...current, [selectedLevel]: newObj };
        } else {
          // Adding the row to selectedRows
          const currLevelObj: { [id: string]: MetaBuyingTableRow } = current[selectedLevel];
          const newObj = { ...currLevelObj, [id]: row };
          updatedSelections = { ...current, [selectedLevel]: newObj };
        }

        // Update downstream selections
        return updateDownstreamSelections(updatedSelections);
      });
    },
    [setSelectedRows, selectedLevel, updateDownstreamSelections]
  );
};

interface TableHeadersRendererParams {
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectAll: Record<string, boolean>;
  setSelectAll: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  selectedLevel: string;
  filteredData: MetaBuyingTableRow[];
}

export const useTableHeadersRenderer = ({
  filteredData,
  selectAll,
  setSelectAll,
  selectedRows,
  setSelectedRows,
  selectedLevel,
}: TableHeadersRendererParams): (({ data, columnIndex }: any) => JSX.Element) => {
  return useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <CheckBox
              className="metaBuyingCheckbox"
              checked={selectAll[selectedLevel]}
              onCheck={() => {
                if (selectAll[selectedLevel]) {
                  setSelectedRows({ ...selectedRows, [selectedLevel]: {} });
                } else {
                  let selectAllMap = { [selectedLevel]: {} };

                  for (let row of filteredData) {
                    selectAllMap[selectedLevel][row.id] = row;
                  }

                  setSelectedRows(current => ({ ...current, ...selectAllMap }));
                }
                setSelectAll({ ...selectAll, [selectedLevel]: !selectAll[selectedLevel] });
              }}
            />
          );
        default:
          return <div>{data}</div>;
      }
    },
    [filteredData, selectAll, setSelectAll, selectedRows, setSelectedRows, selectedLevel]
  );
};

interface FilterBarParams {
  pageTab: "drafts" | "published";
  tableData: any[];
  isInternal: boolean;
  selectedLevel: string;
  setFilter: (func: (line: any) => boolean) => void;
}

export const useFilterBar = ({
  pageTab,
  tableData,
  isInternal,
  selectedLevel,
  setFilter,
}: FilterBarParams): JSX.Element => {
  return useMemo(() => {
    let filterOptions: {
      label: string;
      name: string;
    }[] = [];

    if (!isInternal) {
      filterOptions = [
        { label: "Ad", name: "name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "ad") {
      filterOptions = [
        { label: "Ad", name: "name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "drafts") {
        filterOptions.push(
          { label: "Client-Facing", name: "client_facing" },
          { label: "Approval Status", name: "approval_status" }
        );
      } else {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "adset") {
      filterOptions = [
        { label: "Ad Set", name: "name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else {
      filterOptions = [
        { label: "Campaign", name: "name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    }

    return (
      <FilterBar
        width={1034}
        hasAdvanced={true}
        lines={tableData}
        onFilter={setFilter}
        options={filterOptions}
      />
    );
  }, [isInternal, selectedLevel, tableData, setFilter, pageTab]);
};

interface ViewableDataMapParams {
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
}

// Maps each level to its rows. Data is filtered based on selected rows.
export const useViewableDataMap = ({
  campaignRows,
  adSetRows,
  adRows,
  selectedRows,
}: ViewableDataMapParams): Record<string, MetaBuyingTableRow[]> => {
  return useMemo(() => {
    let viewableAds = adRows;
    if (!R.isEmpty(selectedRows.campaign)) {
      viewableAds = adRows.filter(row => {
        return R.has(row.campaign_id, selectedRows.campaign);
      });
    }
    if (!R.isEmpty(selectedRows.adset)) {
      viewableAds = viewableAds.filter(row => {
        return R.has(row.adset_id, selectedRows.adset);
      });
    }

    let output: Record<string, MetaBuyingTableRow[]> = {
      campaign: campaignRows,
      adset: R.isEmpty(selectedRows.campaign)
        ? adSetRows
        : adSetRows.filter(row => {
            return R.has(row.campaign_id, selectedRows.campaign);
          }),
      ad: viewableAds,
    };

    return output;
  }, [adRows, adSetRows, campaignRows, selectedRows]);
};

interface GetButtonPositionParams {
  buttonRefs: React.MutableRefObject<{}>;
  selectedLevel: string;
}

// Returns the position of the more actions button based on the rowId
export const useGetButtonPosition = ({
  buttonRefs,
  selectedLevel,
}: GetButtonPositionParams): ((
  rowId: any
) => {
  top: number;
  left: number;
}) => {
  return useCallback(
    rowId => {
      const rect = buttonRefs.current[rowId]?.getBoundingClientRect();
      const menuHeight = selectedLevel === "ad" ? 190 : 156;
      const menuWidth = selectedLevel === "ad" ? 167 : 138;
      const buttonHeight = 28;
      return rect
        ? {
            top:
              window.innerHeight - rect.top > menuHeight
                ? rect.top
                : rect.top - menuHeight + buttonHeight,
            left: rect.left - menuWidth,
          }
        : { top: 0, left: 0 };
    },
    [buttonRefs, selectedLevel]
  );
};
