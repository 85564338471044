import "./ConfigColumn.scss";
import { CheckBox, Dropdown, KpiPicker, OnBlurInput, OverlayTrigger } from "../../Components";
import { Column, MetricsTablePreset } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import { MoveColumn, DeleteColumn, COLUMN_DND_TYPE } from "./configUtils";
import { Popover, Button } from "react-bootstrap";
import { roleMap } from "../../UserAdmin/UserAdmin";
import { StateSetter } from "../../utils/types";
import { useDrag, useDrop } from "react-dnd";
import * as C from "@blisspointmedia/bpm-types/dist/CommercePerformance";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import * as L from "@blisspointmedia/bpm-types/dist/LinearPerformance";
import * as R from "ramda";
import * as S from "@blisspointmedia/bpm-types/dist/StreamingPerformance";
import * as SOCIAL from "@blisspointmedia/bpm-types/dist/SocialPerformance";
import * as Y from "@blisspointmedia/bpm-types/dist/YoutubePerformance";
import cn from "classnames";
import ColumnTop from "./ColumnTop";
import React, { useCallback, useMemo, useRef } from "react";
import {
  ColumnMetaData,
  ColumnMetaDataMap,
  resolveDecimals,
} from "../MetricsTable/metricsTableUtils";
import {
  GetKpiMetaDataResponse,
  StreamingColumnCategory,
} from "@blisspointmedia/bpm-types/dist/Performance";

interface OnColumnChange {
  <T extends keyof Column>(i: number, key: T, value: Column[T]): void;
}

interface ConfigColumnProps {
  addColumn: (i: number) => void;
  color?: string;
  column: Column;
  columnMetaDataMap: ColumnMetaDataMap;
  deleteColumn: DeleteColumn;
  globalKpi: string;
  kpiMetaData: GetKpiMetaDataResponse;
  hasSuperHeader: boolean;
  hoverItem?: string;
  index: number;
  isOnlyColumn: boolean;
  moveColumn: MoveColumn;
  onSuperHeaderAdd: () => void;
  prefix: S.Prefix | "linear" | "youtube" | "cross_channel" | "social" | "commerce";
  setPresetChanges: StateSetter<MetricsTablePreset>;
}

const ConfigColumn = ({
  addColumn,
  color,
  column,
  columnMetaDataMap = {},
  deleteColumn,
  globalKpi,
  kpiMetaData = {},
  hasSuperHeader,
  hoverItem,
  index,
  isOnlyColumn,
  moveColumn,
  onSuperHeaderAdd,
  prefix,
  setPresetChanges,
}: ConfigColumnProps): JSX.Element => {
  const {
    audience,
    dataVarName,
    decimals,
    divider,
    heatMapping,
    id,
    kpi,
    label,
    lag,
    role,
  } = column;
  const columnRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const category = useMemo(() => columnMetaDataMap[dataVarName].category, [
    columnMetaDataMap,
    dataVarName,
  ]);

  const [isSpecial, metadata] = useMemo(() => {
    const metadata = { ...columnMetaDataMap[dataVarName] };
    const basicColumnTypesMap = {
      "cross-channel": CC.BASIC_COLUMN_TYPES,
      commerce: C.BASIC_COLUMN_TYPES,
      linear: L.BASIC_COLUMN_TYPES,
      social: SOCIAL.BASIC_COLUMN_TYPES,
      streaming: S.BASIC_COLUMN_TYPES,
      youtube: Y.BASIC_COLUMN_TYPES,
    };
    const basicColumnTypes = R.defaultTo([], basicColumnTypesMap[prefix]);
    // https://github.com/microsoft/TypeScript/issues/26255
    const isSpecial = !(basicColumnTypes as readonly string[]).includes(dataVarName);
    if (isSpecial) {
      const kpiInfo = kpiMetaData[kpi || globalKpi];
      if (kpiInfo && metadata) {
        metadata.decimals = kpiInfo.decimals;
        metadata.displayName = metadata.displayName.replace("CPX", kpiInfo.cpxName);
      }
    }
    return [isSpecial, metadata];
  }, [columnMetaDataMap, dataVarName, prefix, kpiMetaData, kpi, globalKpi]);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: COLUMN_DND_TYPE, id, index },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  });

  const [{ isOver, dropI }, drop] = useDrop({
    accept: COLUMN_DND_TYPE,
    canDrop: item => item.id !== id,
    collect: monitor => ({
      isOver: monitor.isOver() && monitor.canDrop(),
      dropI: monitor.getItem()?.i,
    }),
    drop: (item: { type: typeof COLUMN_DND_TYPE; id: string }) => {
      moveColumn(item.id, column.id);
    },
  });

  drop(preview(columnRef));
  drag(handleRef);

  const defaultLabel = useMemo(() => {
    if (dataVarName.startsWith("avgRevenue")) {
      return `${kpiMetaData[kpi || globalKpi]?.revenueHeader || "Avg. Rev."} (default)`;
    }
    return `${metadata.displayName} (default)`;
  }, [dataVarName, metadata, kpiMetaData, kpi, globalKpi]);

  const onChange = useCallback<OnColumnChange>(
    (i, key, value) => {
      setPresetChanges(changes => ({
        ...changes,
        dataColumns: R.adjust(
          i,
          column => ({
            ...column,
            [key]: value,
          }),
          changes.dataColumns
        ),
      }));
    },
    [setPresetChanges]
  );

  const [categorySelectVal, categorySelectOptions] = useMemo(() => {
    const CATEGORY_OPTIONS = R.map(
      category => ({
        label: category,
        value: category,
      }),
      Object.values(StreamingColumnCategory)
    );

    return [
      category ? { value: category, label: category } : null,
      R.pipe(
        R.values,
        R.sortBy(item => item.label.toLowerCase())
      )(CATEGORY_OPTIONS),
    ];
  }, [category]);

  const getValidTypeOptions = useCallback(
    (category = null) => {
      let columnMetaDataMapFiltered: Record<string, any> = {};
      for (let key of R.keys(columnMetaDataMap)) {
        const col = columnMetaDataMap[key] as ColumnMetaData;
        const categoryToFilterBy = category || categorySelectVal?.value;
        if (!categoryToFilterBy || col.category === categoryToFilterBy) {
          columnMetaDataMapFiltered[key] = columnMetaDataMap[key];
        }
      }
      const TYPE_OPTIONS = R.mapObjIndexed(
        (val, type) => ({
          value: type,
          label: val.displayName || type,
        }),
        columnMetaDataMapFiltered
      );
      return TYPE_OPTIONS;
    },
    [categorySelectVal, columnMetaDataMap]
  );

  const [typeSelectVal, typeSelectOptions] = useMemo(() => {
    const typeOptions = getValidTypeOptions();
    return [
      { value: dataVarName, label: typeOptions[dataVarName].label },
      R.pipe(
        R.values,
        R.sortBy(item => item.label.toLowerCase())
      )(typeOptions),
    ];
  }, [getValidTypeOptions, dataVarName]);

  return (
    <div className="columnConfigOuter" ref={columnRef}>
      <div
        className={cn("columnConfig", {
          hasDivider: divider,
          isOver,
          left: isOver && dropI > index,
          right: isOver && dropI <= index,
          dragging: isDragging,
          isHoverItem: hoverItem === id,
        })}
        key={index}
      >
        <ColumnTop
          addColumn={addColumn}
          color={color}
          handleRef={handleRef}
          hasSuperHeader={hasSuperHeader}
          onSuperHeaderAdd={onSuperHeaderAdd}
          i={index}
        />
        {category && (
          <div className="formSection">
            <Dropdown
              label="Category"
              options={categorySelectOptions}
              value={categorySelectVal?.value}
              onChange={item => {
                const newTypeOptions = getValidTypeOptions(item.value);
                // If the type is no longer a valid option for the category,
                // just default to the first type within the new category.
                if (!Object.keys(newTypeOptions).includes(dataVarName)) {
                  onChange(
                    index,
                    "dataVarName",
                    Object.keys(newTypeOptions)[0] as S.ColumnType | L.ColumnType | Y.ColumnType
                  );
                }
              }}
            />
          </div>
        )}
        <div className="formSection">
          <Dropdown
            label="Type"
            options={R.sortBy(R.prop("label"), typeSelectOptions)}
            value={typeSelectVal.value}
            onChange={value =>
              onChange(index, "dataVarName", value as S.ColumnType | L.ColumnType | Y.ColumnType)
            }
          />
        </div>
        <div className="formSection">
          <div className="label">Label</div>
          <OnBlurInput
            size="sm"
            value={label || ""}
            placeholder={defaultLabel}
            onChange={val => onChange(index, "label", val || undefined)}
          />
        </div>
        {isSpecial && (
          <>
            <div className="basicSection kpiSection">
              <div className="label">
                KPI:{" "}
                {kpi && (
                  <small
                    className="resetToGlobal"
                    onClick={() => onChange(index, "kpi", undefined)}
                  >
                    (Reset to global)
                  </small>
                )}
              </div>
              <OverlayTrigger
                exitOnBackgroundClick
                trigger="click"
                overlay={
                  <Popover id={`${id}_kpi_picker`} className="performanceKpiPickerPopover">
                    <Popover.Content>
                      <KpiPicker
                        kpi={kpi}
                        mediaType="streaming"
                        onChange={kpi => onChange(index, "kpi", kpi)}
                        disableTypeSelector
                      />
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="value">{kpi || "Global"}</div>
              </OverlayTrigger>
            </div>
            <div className="basicSection">
              {!["linear", "youtube"].includes(prefix) && (
                <Dropdown
                  label={"Lag"}
                  onChange={val => onChange(index, "lag", val === "Global" ? undefined : val)}
                  options={["Global", ...S.LAGS] as const}
                  size="sm"
                  value={lag || "Global"}
                />
              )}
              {prefix === "linear" && (
                <Dropdown
                  label={"Audience"}
                  onChange={val => onChange(index, "audience", val === "Global" ? undefined : val)}
                  options={["Global", ...L.AUDIENCES] as const}
                  size="sm"
                  value={audience || "Global"}
                />
              )}
            </div>
          </>
        )}
        <div className="formSection">
          <div className="label">Decimals:</div>
          <OnBlurInput
            size="sm"
            type="number"
            min="0"
            // max={`${MAX_DECIMALS}`}
            value={R.isNil(decimals) ? metadata.decimals || 0 : decimals}
            onChange={decimals => {
              let parsed = parseInt(decimals);
              onChange(
                index,
                "decimals",
                isNaN(parsed) || parsed < 0 ? undefined : resolveDecimals(parsed)
              );
            }}
            placeholder={`${metadata.decimals || 0} (default)`}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Divider?:</div>
          <CheckBox
            checked={!!divider}
            size="sm"
            onCheck={newVal => onChange(index, "divider", newVal)}
          />
        </div>
        <div className="basicSection">
          <Dropdown
            size="sm"
            label="Role"
            value={`${R.defaultTo(10, role)}`}
            options={R.map(
              role => ({
                label: roleMap[role],
                value: role,
              }),
              R.keys(roleMap) as any[]
            )}
            onChange={val => onChange(index, "role", parseInt(val))}
          />
        </div>
        <div className="basicSection checkboxSection">
          <div className="label">Heat Mapping?:</div>
          <CheckBox
            checked={!!heatMapping}
            size="sm"
            onCheck={newVal => onChange(index, "heatMapping", newVal ? {} : undefined)}
          />
        </div>
        {heatMapping && (
          <div className="heatmappingSection">
            <div className="headerSection">
              <div className="label">Heat Mapping</div>
              <OverlayTrigger
                overlay={
                  <Popover
                    id={`${id}_heatmap_glossary_popover`}
                    className="performanceHeatmapGlossaryPopover"
                  >
                    <Popover.Title>Glossary</Popover.Title>
                    <Popover.Content>
                      <div className="glossaryItem">
                        <span>Exclude Zeros:</span>
                        <span>
                          Ignores zeros when calculating. They aren't factored into means, medians,
                          or std. dev. calculations, nor are they considered minimum or maximum
                          values.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Pivot:</span>
                        <span>
                          Point at which colors change. Does not apply to "Green" color scheme.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Mid Point:</span>
                        <span>
                          Half way between the maximum and minimum values (respects Std. Dev.
                          Clipping).
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Color:</span>
                        <span>
                          Color scheme. "Standard" goes from red to yellow, then yellow to green
                          (yellow being at the pivot). "Green" goes from white to green.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Std. Dev. Clipping:</span>
                        <span>
                          Numbers that are greater or less than this number of standard deviations
                          from the mean (not pivot) will not be considered when heat mapping. This
                          is to prevent outliers from impacting the heat mapping.
                        </span>
                      </div>
                      <div className="glossaryItem">
                        <span>Minimum/Maximum:</span>
                        <span>
                          Like "Std. Dev. Clipping", but hard-coded. Numbers below the minimum and
                          above the maximum are treated as if they are those numbers. If you put a
                          maximum of 100, a value of 500 is treated as 100 for heat mapping
                          calculations.
                        </span>
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <div className="glossary">(Glossary)</div>
              </OverlayTrigger>
            </div>
            {!(metadata.contentReplacement?.threshold === 0) && (
              <div className="basicSection checkboxSection">
                <div className="label">Exclude Zeros</div>
                <CheckBox
                  checked={!!heatMapping.excludeZeros}
                  size="sm"
                  onCheck={newVal =>
                    onChange(index, "heatMapping", { ...heatMapping, excludeZeros: newVal })
                  }
                />
              </div>
            )}
            <div className="basicSection">
              <Dropdown
                label="Pivot"
                onChange={val => onChange(index, "heatMapping", { ...heatMapping, midpoint: val })}
                options={["MEDIAN", "MIDPOINT", "MEAN", "CUSTOM"]}
                size="sm"
                value={heatMapping.midpoint || "MIDPOINT"}
              />
            </div>
            {heatMapping.midpoint === "CUSTOM" && (
              <div className="formSection">
                <div className="label">Custom Pivot:</div>
                <OnBlurInput
                  size="sm"
                  type="number"
                  value={heatMapping.customMidpoint || ""}
                  onChange={val => {
                    onChange(index, "heatMapping", {
                      ...R.omit(["customMidpoint"], heatMapping),
                      customMidpoint: parseFloat(val) || undefined,
                    });
                  }}
                  placeholder="Custom pivot"
                />
              </div>
            )}
            <div className="basicSection">
              <Dropdown
                size="sm"
                label="Color"
                value={heatMapping.colorScheme || "diverging"}
                options={[
                  { label: "Diverging", value: "diverging" },
                  { label: "Sequential", value: "sequential" },
                ]}
                onChange={val =>
                  onChange(index, "heatMapping", { ...heatMapping, colorScheme: val })
                }
              />
            </div>
            <div className="basicSection checkboxSection">
              <div className="label">Min Is Best?:</div>
              <CheckBox
                checked={
                  R.isNil(heatMapping.minIsBest)
                    ? columnMetaDataMap[dataVarName].minIsBest
                    : heatMapping.minIsBest
                }
                size="sm"
                onCheck={newVal =>
                  onChange(index, "heatMapping", {
                    ...R.omit(["minIsBest"], heatMapping),
                    minIsBest: newVal,
                  })
                }
              />
            </div>
            <div className="formSection">
              <div className="label">Std. Dev. Clipping:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.stdevClipping || ""}
                onChange={val => {
                  onChange(index, "heatMapping", {
                    ...R.omit(["stdevClipping"], heatMapping),
                    stdevClipping: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
            <div className="formSection">
              <div className="label">Minimum:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.min || ""}
                onChange={val => {
                  onChange(index, "heatMapping", {
                    ...R.omit(["min"], heatMapping),
                    min: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
            <div className="formSection">
              <div className="label">Maximum:</div>
              <OnBlurInput
                size="sm"
                type="number"
                value={heatMapping.max || ""}
                onChange={val => {
                  onChange(index, "heatMapping", {
                    ...R.omit(["max"], heatMapping),
                    max: parseFloat(val) || undefined,
                  });
                }}
                placeholder="None (default)"
              />
            </div>
          </div>
        )}
        {!isOnlyColumn && (
          <Button
            size="sm"
            variant="outline-danger"
            className="deleteButton"
            onClick={() => deleteColumn(index)}
          >
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConfigColumn;
