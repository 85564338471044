import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MdSave } from "react-icons/md";
import { Response, ClientInfo } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { useSetError } from "../../redux/modals";
import * as UserRedux from "../../redux/user";
import WidgetContainer from "../../Components/WidgetContainer";
import { Button, ButtonType } from "../../Components";
import { platformAccessOptions } from "./PlatformAccessOptions";
import OnboardingFreeForm from "../OnboardingFreeForm";
import { saveClientInfo, getCompletedPlatformPercentage } from "../OnboardingUtils";

interface PlatformAccessProps {
  clientInfo: ClientInfo;
  onPercentageChange: (percentage: number) => void;
}

const PlatformAccess: React.FC<PlatformAccessProps> = ({ clientInfo, onPercentageChange }) => {
  const [allResponses, setAllResponses] = useState<ClientInfo>(clientInfo);
  const [pendingResponses, setPendingResponses] = useState<Response[]>([]);
  const setError = useSetError();
  const user = useSelector(UserRedux.fullNameSelector);

  const platformOptions = platformAccessOptions(allResponses.responses);

  useEffect(() => {
    const totalPercentage = getCompletedPlatformPercentage(allResponses.responses, platformOptions);
    onPercentageChange(totalPercentage);
  }, [allResponses.responses, onPercentageChange, platformOptions]);

  const handlePlatformAccessChange = (updatedResponses: Response[]) => {
    setPendingResponses(updatedResponses);
  };

  useEffect(() => {
    if (pendingResponses.length > 0) {
      setAllResponses(prevState => ({
        ...prevState,
        responses: pendingResponses,
      }));
    }
  }, [pendingResponses]);

  const platformForm = (key: string, option) => {
    return (
      <div>
        <OnboardingFreeForm
          questions={option.table}
          responses={allResponses.responses}
          onDataChange={handlePlatformAccessChange}
        />
      </div>
    );
  };

  const handleSave = async () => {
    try {
      await saveClientInfo({
        company: allResponses.company,
        responses: allResponses.responses,
        user,
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>
        This is the platform / source checklist so we know which platforms and sources to populate
        later on in these pages.
      </h2>
      {Object.keys(platformOptions).map(key => {
        const disabled = platformOptions[key].table.rows.length === 0;
        return (
          <WidgetContainer
            key={key}
            backgroundColor="white"
            header={platformOptions[key].title}
            design="secondary"
            disabled={disabled}
            collapsible
            startOpen={false}
            rightActions={
              <Button type={ButtonType.FILLED} icon={<MdSave />} onClick={handleSave} />
            }
          >
            {platformForm(key, platformOptions[key])}
          </WidgetContainer>
        );
      })}
    </div>
  );
};

export default PlatformAccess;
