import { FormLayoutProps } from "../OnboardingFreeForm";

const PLATFORM_CHECKLIST_COLUMNS = [
  {
    title: "primary",
    type: "header",
    width: 1,
  },
  {
    title: "Do you run media on this platform?",
    type: "checkbox",
    width: 2,
    required: true,
  },
  {
    title: "Tinuiti Managing?",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Yes", "No"],
  },
  {
    title: "Current Status",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Yes", "No"],
  },
  {
    title: "Additional Info",
    type: "text",
    width: 5,
    required: false,
    textPlaceholder: "Add Additional Info",
  },
];

const SOCIAL_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Social-1",
      title: "LinkedIn",
    },
    {
      id: "2",
      questionNumber: "1Social-2",
      title: "Meta",
    },
    {
      id: "3",
      questionNumber: "1Social-3",
      title: "Reddit",
    },
    {
      id: "4",
      questionNumber: "1Social-4",
      title: "TikTok",
    },
  ],
} as FormLayoutProps;

const SOURCE_OF_TRUTH_CHECKLIST_FORM = {
  columns: PLATFORM_CHECKLIST_COLUMNS,
  rows: [
    {
      id: "1",
      questionNumber: "1Source-1",
      title: "Google Analytics",
    },
    {
      id: "2",
      questionNumber: "1Source-2",
      title: "Google Tag Manager",
    },
    {
      id: "3",
      questionNumber: "1Source-3",
      title: "Facebook Pixel",
    },
    {
      id: "4",
      questionNumber: "1Source-4",
      title: "Other",
    },
  ],
} as FormLayoutProps;

export interface PlatformOptions {
  [key: string]: {
    simpleId: string;
    title: string;
    table: FormLayoutProps;
  };
}

export const PLATFORM_OPTIONS = {
  sourceOfTruth: {
    simpleId: "1Source",
    title: "Source Of Truth*",
    table: SOURCE_OF_TRUTH_CHECKLIST_FORM,
  },
  affiliateLifecycle: {
    simpleId: "1Affiliate",
    title: "Affiliate, Lifecycle",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
  marketplaces: {
    simpleId: "1Marketplaces",
    title: "Marketplaces",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
  programmaticDisplay: {
    simpleId: "1Programmatic",
    title: "Programmatic, Display, OTT, Streaming",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
  searchShopping: {
    simpleId: "1Search",
    title: "Search & Shopping",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
  seoCro: {
    simpleId: "1SEO",
    title: "SEO, CRO",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
  social: {
    simpleId: "1Social",
    title: "Social",
    table: SOCIAL_CHECKLIST_FORM,
  },
  misc: {
    simpleId: "1Misc",
    title: "Misc",
    table: { columns: PLATFORM_CHECKLIST_COLUMNS, rows: [] },
  },
} as PlatformOptions;
