import "./PathingAnalysis.scss";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import * as R from "ramda";
import { useQuery } from "@apollo/client";
import { useCompanyInfo } from "../../../redux/company";
import { useSetError } from "../../../redux/modals";
import {
  BPMTable,
  FullPageSpinner,
  Spinner,
  Page,
  Dropdown,
  DropdownToggleType,
  OverlayTrigger,
} from "../../../Components";
import BarChart from "../../AMCHorizontalBarChart";
import { amcClient } from "../../Cache";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip as PieChartTooltip,
  Sector,
} from "recharts";
import {
  GET_PA_FILTERS,
  GET_PA_TOUCHPOINT_DATA,
  GET_PA_OCCURENCE_DATA,
  GET_PA_PATH_DATA,
  GET_PA_COMBINED_DATA,
} from "../../Queries";
import {
  campaignPathTableHeaders,
  campaignAttrTableHeaders,
  campaignAttrTableTooltips,
  adTypePathTableHeaders,
  formatNumberWithCommas,
  AD_TYPE_CHART_COLORS,
  DATE_FORMAT,
  DATE_LABEL_FORMAT,
  FILTER_COLUMN_WIDTH,
  BAR_FILL_COLOR,
  ATTRIBUTION_MODELS,
  HEADER_HEIGHT,
  BAR_CHART_HEIGHT,
  BAR_CHART_FONT_SIZE,
} from "./PathingAnalysisConfig";
import { RouteComponentProps } from "@reach/router";
import { AdPathFilterMenu } from "./AdPathFilters";
import { Tooltip } from "react-bootstrap";
import * as Dfns from "date-fns/fp";
import _ from "lodash";

/*eslint-disable no-empty-pattern*/
const PathingAnalysis = ({}: RouteComponentProps): JSX.Element => {
  const { cid, amc_instance_id: amcInstanceId } = useCompanyInfo();

  const setError = useSetError();

  const dateObj = new Date();
  dateObj.setMonth(dateObj.getMonth() - 1);
  const lastMonth = Dfns.format(DATE_FORMAT, dateObj);

  const getFirstDayOfMonth = date => {
    const dateTokens = date.split("-");
    return `${dateTokens[0]}-${dateTokens[1]}-01`;
  };
  const getLastDayOfMonth = date =>
    Dfns.format(DATE_FORMAT, Dfns.lastDayOfMonth(Dfns.parseISO(date)));

  const [dateRange, updateDateRange] = useState({
    startDate: getFirstDayOfMonth(lastMonth),
    endDate: getLastDayOfMonth(lastMonth),
  });

  const getDateLabel = date => Dfns.format(DATE_LABEL_FORMAT, Dfns.parseISO(date));

  const getReportMonthLabel = useCallback(
    (date: string) => `${getDateLabel(date)} - ${getDateLabel(getLastDayOfMonth(date))}`,
    []
  );

  // Used to determine which pie chart tooltip to show
  const [activeIndices, setActiveIndices] = useState({
    chartIndex: -1,
    sliceIndex: -1,
  });

  const [filtersData, setFiltersData] = useState([
    {
      label: "Amazon DSP",
      columnWidth: FILTER_COLUMN_WIDTH,
      data: [
        {
          name: "Display",
          label: "Display",
          value: "include",
        },
        {
          name: "OLV",
          label: "OLV",
          value: "include",
        },
        {
          name: "STV",
          label: "STV",
          value: "include",
        },
        {
          name: "PV",
          label: "Prime Video (PV)",
          value: "include",
        },
      ],
    },
    {
      label: "Sponsored Ads",
      columnWidth: FILTER_COLUMN_WIDTH,
      data: [
        {
          name: "SP",
          label: "Sponsored Products",
          value: "include",
        },
        {
          name: "SB",
          label: "Sponsored Brand",
          value: "include",
        },
        {
          name: "SD",
          label: "Sponsored Display",
          value: "include",
        },
        {
          name: "ST",
          label: "Sponsored TV (ST)",
          value: "include",
        },
      ],
    },
  ]);

  const selectAdTypeFilters = (value: string, rowIndex: number, filterIndex: number) => {
    const newFiltersData = R.clone(filtersData);
    newFiltersData[rowIndex].data[filterIndex].value = value;
    setFiltersData(newFiltersData);
  };

  let queryFilterData = useQuery(GET_PA_FILTERS, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  let queryTouchpointData = useQuery(GET_PA_TOUCHPOINT_DATA, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  let queryOccurenceData = useQuery(GET_PA_OCCURENCE_DATA, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  let queryPathData = useQuery(GET_PA_PATH_DATA, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  let queryCombinedData = useQuery(GET_PA_COMBINED_DATA, {
    client: amcClient,
    variables: {
      amcInstanceId,
      dateRange,
    },
  });

  useEffect(() => {
    let errors: string[] = [];
    if (queryFilterData.error && queryFilterData.error.message) {
      errors.push(`Available Filters query — ${queryFilterData.error.message}`);
    }
    if (queryTouchpointData.error && queryTouchpointData.error.message) {
      errors.push(`Ad Type Touchpoint Data query — ${queryTouchpointData.error.message}`);
    }
    if (queryOccurenceData.error && queryOccurenceData.error.message) {
      errors.push(`Campaign & Ad Type Occurence Data query — ${queryOccurenceData.error.message}`);
    }
    if (queryPathData.error && queryPathData.error.message) {
      errors.push(`Campaign & Ad Type Path Data query — ${queryPathData.error.message}`);
    }
    if (queryCombinedData.error && queryCombinedData.error.message) {
      errors.push(
        `First Last Linear & Position Combined Data query — ${queryCombinedData.error.message}`
      );
    }
    if (errors.length > 0) {
      setError({
        title: "AMC Pathing Report Query Error(s)",
        message: `• ${R.join("\n• ", errors)}`,
      });
    }
  }, [
    queryFilterData,
    queryTouchpointData,
    queryOccurenceData,
    queryPathData,
    queryCombinedData,
    setError,
  ]);

  const dateOptions = useMemo(
    () =>
      queryFilterData && queryFilterData.data
        ? queryFilterData.data?.amc.pathingAnalysisReport.availableFilters.monthlyStartDates?.map(
            startDate => ({
              value: startDate,
              label: getReportMonthLabel(startDate),
            })
          )
        : [],
    [queryFilterData, getReportMonthLabel]
  );

  // Heatmap values are calculated relative to the maximum value of a given data type
  const getMaxData = (data, dataTypes) => {
    let maximums: Record<string, number> = {};
    dataTypes.forEach(dataType => {
      if (data.length > 0) {
        maximums[dataType] =
          _.max(
            data.map(row => (R.is(Number, Number(row[dataType])) ? Number(row[dataType]) : -1))
          ) || -1;
      } else {
        maximums[dataType] = -1;
      }
    });
    return maximums;
  };

  const filterTouchpointData = (
    data: {
      adType: string;
      amcInstanceId: string;
      impressions: string | number;
      newToBrandTotalProductSales: string | number;
      newToBrandTotalPurchases: string | number;
      ntbUser: string | number;
      reach: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      totalProductSales: string | number;
      totalPurchases: string | number;
      touchpoint: string;
      userThatPurchased: string | number;
    }[],
    touchpoint: string
  ) =>
    R.filter(
      (entry: any) => entry.touchpoint === touchpoint,
      data.map(entry => ({
        ...entry,
        totalPurchases: Number(entry.totalPurchases),
        newToBrandTotalPurchases: Number(entry.newToBrandTotalPurchases),
      }))
    );

  const filterCampaignTypes = (
    data: {
      amcInstanceId: string;
      campaignAndAdType: string;
      dimensionLevel: string;
      impressions: string | number;
      reach: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      totalCost: string | number;
      totalPurchases: string | number;
      userThatPurchased: string | number;
    }[]
  ) =>
    R.sortBy(
      (item: any) => -Math.abs(Number(item.userThatPurchased)),
      R.filter(
        (entry: Record<string, any>) => entry.dimensionLevel.toLocaleLowerCase() === "campaign",
        data.map(entry => ({
          ...entry,
          userThatPurchased: Number(entry.userThatPurchased),
        }))
      )
    );

  // Get list of ad types to filter in or out (i.e. require vs. exclude)
  const setInclusionFilter = type =>
    R.filter(
      (filter: { name: string; label: string; value: string }) => filter.value === type,
      R.concat(filtersData[0].data, filtersData[1].data)
    ).map(filter => filter.name.toLocaleLowerCase());

  // Get ad type from path for comparison with filter
  const checkPathFilter = (path, key) =>
    path
      .split(",")
      .map(adPathType => adPathType.split("-")[1].toLocaleLowerCase())
      .includes(key);

  const checkAgainstFilters = (data, filterData, filterType: "require" | "exclude") =>
    R.isEmpty(filterData)
      ? data
      : R.filter(
          (entry: Record<string, any>) =>
            filterType === "require"
              ? filterData.every(key => checkPathFilter(entry.path, key))
              : !filterData.some(key => checkPathFilter(entry.path, key)),
          data
        );

  const filterAdTypes = (
    data: {
      amcInstanceId: string;
      impressions: string | number;
      newToBrandTotalProductSales: string | number;
      newToBrandTotalPurchases: string | number;
      ntbUser: string | number;
      path: string;
      pathType: string;
      reach: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      subscribeAndSave: string | number;
      totalCost: string | number;
      totalProductSales: string | number;
      totalPurchases: string | number;
      userThatPurchased: string | number;
    }[]
  ) => {
    const requireFilters = setInclusionFilter("require");
    const excludeFilters = setInclusionFilter("exclude");
    let filteredData = R.filter(
      (entry: Record<string, any>) =>
        entry.pathType && entry.pathType.toLocaleLowerCase() === "ad_type path",
      data.map(entry => ({
        ...entry,
        impressions: Number(entry.impressions),
        newToBrandTotalProductSales: Number(entry.newToBrandTotalProductSales),
        newToBrandTotalPurchases: Number(entry.newToBrandTotalPurchases),
        ntbUser: Number(entry.ntbUser),
        reach: Number(entry.reach),
        subscribeAndSave: Number(entry.subscribeAndSave),
        totalCost: Number(entry.totalCost),
        totalProductSales: Number(entry.totalProductSales),
        totalPurchases: Number(entry.totalPurchases),
        userThatPurchased: Number(entry.userThatPurchased),
        purchaseRate:
          Math.round((Number(entry.totalPurchases) / Number(entry.impressions)) * 100000) / 1000,
      }))
    );
    filteredData = checkAgainstFilters(filteredData, requireFilters, "require");
    filteredData = checkAgainstFilters(filteredData, excludeFilters, "exclude");
    const maxData = getMaxData(filteredData, [
      "reach",
      "impressions",
      "totalCost",
      "userThatPurchased",
      "totalPurchases",
      "purchaseRate",
    ]);
    return filteredData.map(entry => ({
      ...entry,
      heatMapValue_reach: entry.reach / maxData.reach,
      heatMapValue_impressions: entry.impressions / maxData.impressions,
      heatMapValue_totalCost: entry.totalCost / maxData.totalCost,
      heatMapValue_userThatPurchased: entry.userThatPurchased / maxData.userThatPurchased,
      heatMapValue_totalPurchases: entry.totalPurchases / maxData.totalPurchases,
      heatMapValue_purchaseRate: entry.purchaseRate / maxData.purchaseRate,
    }));
  };

  const formatCampaignAndAdTypeData = (
    data: {
      amcInstanceId: string;
      impressions: string | number;
      newToBrandTotalProductSales: string | number;
      newToBrandTotalPurchases: string | number;
      ntbUser: string | number;
      path: string;
      pathType: string;
      reach: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      subscribeAndSave: string | number;
      totalCost: string | number;
      totalProductSales: string | number;
      totalPurchases: string | number;
      userThatPurchased: string | number;
    }[]
  ) => {
    const sortedData = R.sortBy(
      (item: any) => -Math.abs(Number(item.totalPurchases)),
      data.map(entry => ({
        ...entry,
        impressions: Number(entry.impressions),
        newToBrandTotalProductSales: Number(entry.newToBrandTotalProductSales),
        newToBrandTotalPurchases: Number(entry.newToBrandTotalPurchases),
        ntbUser: Number(entry.ntbUser),
        reach: Number(entry.reach),
        subscribeAndSave: Number(entry.subscribeAndSave),
        totalCost: Number(entry.totalCost),
        totalProductSales: Number(entry.totalProductSales),
        totalPurchases: Number(entry.totalPurchases),
        userThatPurchased: Number(entry.userThatPurchased),
      }))
    );
    const maxData = getMaxData(sortedData, [
      "reach",
      "impressions",
      "totalCost",
      "totalPurchases",
      "totalProductSales",
    ]);
    return sortedData.map(entry => ({
      ...entry,
      heatMapValue_reach: entry.reach / maxData.reach,
      heatMapValue_impressions: entry.impressions / maxData.impressions,
      heatMapValue_totalCost: entry.totalCost / maxData.totalCost,
      heatMapValue_totalPurchases: entry.totalPurchases / maxData.totalPurchases,
      heatMapValue_totalProductSales: entry.totalProductSales / maxData.totalProductSales,
    }));
  };

  const filterFirstTouchCampaignData = (
    data: {
      amcInstanceId: string;
      attributionModel: string;
      campaign: string | null;
      clicks: string | number;
      conversions: string | number;
      impressions: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      spend: string | number;
      totalProductSales: string | number;
      totalUnitsSold: string | number;
      userReach: string | number;
      usersThatConverted: string | number;
    }[]
  ) =>
    R.sortBy(
      (item: any) => -Math.abs(Number(item.usersThatConverted)),
      R.filter(
        (entry: Record<string, any>) =>
          entry.attributionModel.toLocaleLowerCase() === "first touch" && !R.isNil(entry.campaign),
        data
      )
    );

  // Group all attribution models for a campaign together
  const groupAttributionData = (
    data: {
      amcInstanceId: string;
      attributionModel: string;
      campaign: string | null;
      clicks: string;
      conversions: number;
      impressions: string;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      spend: number | string;
      totalProductSales: number;
      totalUnitsSold: number;
      userReach: string;
      usersThatConverted: number;
    }[]
  ) => {
    let groups = {};
    data.forEach(entry => {
      if (!R.isNil(entry.campaign)) {
        if (R.isNil(groups[entry.campaign])) {
          groups[entry.campaign] = [entry];
        } else {
          groups[entry.campaign].push(entry);
        }
      }
    });
    let groupedData: Record<string, any>[] = [];
    Object.keys(groups).forEach(key => {
      let campaignEntries = {
        campaign: key,
      };
      groups[key].forEach(entry => {
        campaignEntries[entry.attributionModel] =
          Number(entry.totalProductSales) / Number(entry.spend);
      });
      ATTRIBUTION_MODELS.forEach(attributionModel => {
        if (R.isNil(campaignEntries[attributionModel])) {
          campaignEntries[attributionModel] = 0;
        }
      });
      groupedData.push(campaignEntries);
    });
    const maxData = getMaxData(groupedData, ["First Touch", "Last Touch", "Linear", "Position"]);
    return groupedData.map(entry => ({
      ...entry,
      heatMapValue_firstTouch: entry["First Touch"] / maxData["First Touch"],
      heatMapValue_lastTouch: entry["Last Touch"] / maxData["Last Touch"],
      heatMapValue_linear: entry.Linear / maxData.Linear,
      heatMapValue_position: entry.Position / maxData.Position,
    }));
  };

  const calculateTotalPurchaseData = (
    data: {
      adType: string;
      amcInstanceId: string;
      impressions: string;
      newToBrandTotalProductSales: string | number;
      newToBrandTotalPurchases: string | number;
      ntbUser: number;
      reach: string | number;
      reportDate: string;
      reportEndDate: string;
      reportStartDate: string;
      totalProductSales: string | number;
      totalPurchases: string | number;
      touchpoint: string;
      userThatPurchased: string | number;
    }[]
  ) =>
    R.sortBy(
      (item: any) => -Math.abs(item.totalPurchases),
      R.values(
        R.groupBy(
          R.prop("adType"),
          data.map(entry => ({
            adType: entry.adType,
            totalPurchases: Number(entry.totalPurchases),
          }))
        )
      ).map(entry => ({
        adType: entry[0].adType,
        totalPurchases: R.pipe(R.map(R.prop("totalPurchases")), R.sum)(entry),
      }))
    );

  const campaignOccrData = queryOccurenceData.data
    ? filterCampaignTypes(
        queryOccurenceData.data?.amc.pathingAnalysisReport.campaignAndAdTypeOccuranceData
      )
    : [];

  const campaignAndAdTypePathData = queryPathData.data
    ? formatCampaignAndAdTypeData(
        queryPathData.data?.amc.pathingAnalysisReport.campaignAndAdTypePathData
      )
    : [];

  const adTypePathData = queryPathData.data
    ? filterAdTypes(queryPathData.data?.amc.pathingAnalysisReport.campaignAndAdTypePathData)
    : [];

  const campaignAttrData = queryCombinedData.data
    ? groupAttributionData(
        queryCombinedData.data?.amc.pathingAnalysisReport.firstLastLinearAndPositionCombinedData
      )
    : [];

  const adTypeTotalPurchaseData = queryTouchpointData.data
    ? calculateTotalPurchaseData(
        queryTouchpointData.data?.amc.pathingAnalysisReport.adTypeTouchpointData
      )
    : [];

  const firstTouchpointData = useMemo(
    () =>
      queryTouchpointData && queryTouchpointData.data
        ? filterTouchpointData(
            queryTouchpointData.data?.amc.pathingAnalysisReport.adTypeTouchpointData,
            "first_touchpoint"
          )
        : [],
    [queryTouchpointData]
  );
  const lastTouchpointData = useMemo(
    () =>
      queryTouchpointData && queryTouchpointData.data
        ? filterTouchpointData(
            queryTouchpointData.data?.amc.pathingAnalysisReport.adTypeTouchpointData,
            "last_touchpoint"
          )
        : [],
    [queryTouchpointData]
  );
  const firstTouchCampaignData = queryCombinedData.data
    ? filterFirstTouchCampaignData(
        queryCombinedData.data?.amc.pathingAnalysisReport.firstLastLinearAndPositionCombinedData
      )
    : [];

  const touchpointsData = useMemo(
    () => [
      {
        touchpointData: firstTouchpointData,
        conversionsType: "totalPurchases",
        header: "​What channel has the highest % of conversions when served first",
      },
      {
        touchpointData: lastTouchpointData,
        conversionsType: "totalPurchases",
        header: "What channel has the highest % of NTB conversions when served first",
      },
      {
        touchpointData: firstTouchpointData,
        conversionsType: "newToBrandTotalPurchases",
        header: "What channel has the highest % of conversions when served last",
      },
      {
        touchpointData: lastTouchpointData,
        conversionsType: "newToBrandTotalPurchases",
        header: "What channel has the highest % of NTB conversions when served last",
      },
    ],
    [firstTouchpointData, lastTouchpointData]
  );

  const [hoveredAttribute, setHoveredAttribute] = useState<{
    adType: string;
    conversions: number;
    percent: number;
  }>({ adType: "", conversions: 0, percent: 0 });

  const renderCustomizedLabel = ({
    index,
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    entries,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius + 30) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill={
          AD_TYPE_CHART_COLORS[R.defaultTo("", entries[index].adType).toLocaleLowerCase()].textColor
        }
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className="percent-label"
      >
        {percent > 0.05 ? `${(Math.round(percent * 1000) / 10).toFixed(1)}%` : ""}
      </text>
    );
  };

  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius + 8}
        fill={`${fill}4d`}
      />
    </g>
  );

  const updateDateSelection = dateSelection => {
    updateDateRange({
      startDate: dateSelection,
      endDate: getLastDayOfMonth(dateSelection),
    });
  };

  const renderTouchpoint = ({ touchpointData, conversionsType, header }, chartIndex) => (
    <div className="path-analysis-touchpoint" key={chartIndex}>
      <div className="touchpoint-header">{header}</div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <PieChartTooltip
            key="name"
            content={() =>
              activeIndices.chartIndex === chartIndex ? (
                <div className="pie-chart-tooltip">
                  {hoveredAttribute.adType}
                  <br />
                  <b>{`${formatNumberWithCommas(hoveredAttribute.conversions)} (${(
                    Math.round(hoveredAttribute.percent * 1000) / 10
                  ).toFixed(1)}%)`}</b>
                </div>
              ) : null
            }
            cursor={true}
          />
          <Pie
            data={touchpointData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={pieData =>
              renderCustomizedLabel({
                ...pieData,
                entries: touchpointData,
              })
            }
            outerRadius={120}
            blendStroke={true}
            startAngle={-270}
            dataKey={conversionsType}
            activeIndex={activeIndices.chartIndex === chartIndex ? activeIndices.sliceIndex : -1}
            activeShape={renderActiveShape}
            onMouseEnter={(slice, sliceIndex) => {
              setActiveIndices({
                chartIndex,
                sliceIndex,
              });
              setHoveredAttribute({
                adType: slice.adType,
                conversions: slice[conversionsType],
                percent: slice.percent,
              });
            }}
            onMouseOut={() => {
              setActiveIndices({
                chartIndex: -1,
                sliceIndex: -1,
              });
              setHoveredAttribute({ adType: "", conversions: 0, percent: 0 });
            }}
            cursor="pointer"
          >
            {touchpointData.map(entry => {
              return (
                <Cell
                  key={R.defaultTo("", entry.adType)}
                  fill={
                    AD_TYPE_CHART_COLORS[R.defaultTo("", entry.adType).toLocaleLowerCase()]
                      .fillColor
                  }
                />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );

  return (
    <Page minHeight="600px" pageType="AMC Pathing Analysis" title="AMC Pathing Analysis">
      {queryFilterData.loading && !queryFilterData.data && <FullPageSpinner />}
      {!queryFilterData.loading && !queryFilterData.data ? (
        <div className="empty-message">{`No AMC reports available for ${cid}.`}</div>
      ) : (
        <div className="path-analysis-page">
          <div className="path-analysis-header">
            <div className="path-analysis-disclaimer">
              Note: Attribution window used is 28 days.
            </div>
            <Dropdown
              className="path-analysis-date-selection"
              type={DropdownToggleType.OUTLINED}
              design="secondary"
              onChange={option => updateDateSelection(option)}
              options={dateOptions}
              value={dateRange.startDate}
              label={<span>{getReportMonthLabel(dateRange.startDate)}</span>}
            />
          </div>

          <div className="touchpoint-legend-wrapper">
            Legend{" "}
            <div className="touchpoint-legend">
              {Object.keys(AD_TYPE_CHART_COLORS).map(key => (
                <div className="touchpoint-legend-key" key={key}>
                  <div
                    className="touchpoint-legend-color"
                    style={{ backgroundColor: AD_TYPE_CHART_COLORS[key].fillColor }}
                  />
                  {AD_TYPE_CHART_COLORS[key].label}
                </div>
              ))}
            </div>
          </div>
          <div className="path-analysis-row">
            {touchpointsData.map((entry, index) => renderTouchpoint(entry, index))}
          </div>

          <div className="row-header">
            What campaign paths were included in most customer paths to conversion
          </div>
          <div className="path-analysis-row">
            <div className="basic-card">
              <div className="table-wrapper">
                {queryPathData.loading && !queryPathData.data ? (
                  <Spinner size={100} />
                ) : !queryPathData.loading && !queryPathData.data ? (
                  <div className="empty-message">Failed to fetch data</div>
                ) : (
                  <BPMTable
                    data={campaignAndAdTypePathData}
                    filterBar={false}
                    headers={campaignPathTableHeaders}
                    noRowsRenderer={() => <div className="noFailures">No data to show</div>}
                    pagination={true}
                    alternateColors={false}
                    headerHeight={HEADER_HEIGHT}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row-header">Percent of Conversions by ad type shown</div>
          <div className="path-analysis-row">
            <div className="chart-wrapper">
              {queryTouchpointData.loading && !queryTouchpointData.data ? (
                <Spinner size={100} />
              ) : !queryTouchpointData.loading && !queryTouchpointData.data ? (
                <div className="empty-message">Failed to fetch data</div>
              ) : (
                <BarChart
                  data={adTypeTotalPurchaseData}
                  barKey={"totalPurchases"}
                  barFillColor={BAR_FILL_COLOR}
                  yAxisKey={"adType"}
                  yAxisLabel={"Total Purchases"}
                  height={400}
                />
              )}
            </div>
          </div>

          <div className="row-header">
            What campaigns were included in the most customer paths to conversion
          </div>
          <div className="path-analysis-row">
            <div className="chart-wrapper">
              {queryOccurenceData.loading && !queryOccurenceData.data ? (
                <Spinner size={100} />
              ) : !queryOccurenceData.loading && !queryOccurenceData.data ? (
                <div className="empty-message">Failed to fetch data</div>
              ) : (
                <BarChart
                  data={campaignOccrData}
                  barKey={"userThatPurchased"}
                  barFillColor={BAR_FILL_COLOR}
                  yAxisKey={"campaignAndAdType"}
                  yAxisLabel={"Total Purchasers"}
                  height={BAR_CHART_HEIGHT}
                  fontSize={BAR_CHART_FONT_SIZE}
                />
              )}
            </div>
          </div>

          <div className="row-header">
            What campaigns are the best first touch campaigns to lead a customer to a conversion
          </div>
          <div className="path-analysis-row">
            <div className="chart-wrapper">
              {queryCombinedData.loading && !queryCombinedData.data ? (
                <Spinner size={100} />
              ) : !queryCombinedData.loading && !queryCombinedData.data ? (
                <div className="empty-message">Failed to fetch data</div>
              ) : (
                <BarChart
                  data={firstTouchCampaignData}
                  barKey={"usersThatConverted"}
                  barFillColor={BAR_FILL_COLOR}
                  yAxisKey={"campaign"}
                  yAxisLabel={"First Touch Purchasers"}
                  height={BAR_CHART_HEIGHT}
                  fontSize={BAR_CHART_FONT_SIZE}
                />
              )}
            </div>
          </div>

          <div className="row-header">
            What campaigns performed the best based on the different attribution models?
          </div>
          <div className="path-analysis-row">
            <div className="basic-card">
              <div className="table-wrapper attribution-table">
                {queryCombinedData.loading && !queryCombinedData.data ? (
                  <Spinner size={100} />
                ) : !queryCombinedData.loading && !queryCombinedData.data ? (
                  <div className="empty-message">Failed to fetch data</div>
                ) : (
                  <BPMTable
                    data={campaignAttrData}
                    filterBar={false}
                    headers={campaignAttrTableHeaders}
                    headersRenderer={({ data }) => (
                      <OverlayTrigger
                        placement={OverlayTrigger.PLACEMENTS.TOP.RIGHT}
                        overlay={
                          <Tooltip id={`tooltip-${data}`}>
                            {campaignAttrTableTooltips[`${data}`]}
                          </Tooltip>
                        }
                      >
                        <span>{data}</span>
                      </OverlayTrigger>
                    )}
                    noRowsRenderer={() => <div className="noFailures">No data to show</div>}
                    pagination={true}
                    alternateColors={false}
                    headerHeight={HEADER_HEIGHT}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row-header">
            What is the purchase rate for a customer based on the different campaign types that they
            were shown on their path to purchase?
          </div>
          <div className="path-analysis-row">
            <div className="basic-card ad-path">
              <AdPathFilterMenu filtersRowData={filtersData} onSelect={selectAdTypeFilters} />
              <div className="table-wrapper ad-path">
                {queryPathData.loading && !queryPathData.data ? (
                  <Spinner size={100} />
                ) : !queryPathData.loading && !queryPathData.data ? (
                  <div className="empty-message">Failed to fetch data</div>
                ) : (
                  <BPMTable
                    data={adTypePathData}
                    filterBar={false}
                    headers={adTypePathTableHeaders}
                    noRowsRenderer={() => <div className="noFailures">No data to show</div>}
                    pagination={true}
                    alternateColors={false}
                    headerHeight={HEADER_HEIGHT}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default PathingAnalysis;
