import React, { useCallback, useContext, useState, useMemo } from "react";
import * as R from "ramda";
import { MdArrowBackIosNew, MdArrowForwardIos, MdDoubleArrow } from "react-icons/md";
import Select from "react-select";
import { KpiMappingsContext } from "./KpiMapping";
import {
  CombinedCrossChannelKpis,
  HandleDeleteMappingInput,
  HandleKpiEditInput,
  HandleLagEditInput,
  HandleNewRowInput,
  makeEditKey,
} from "./EditKpiMapping";
import {
  KpiEdits,
  SourceMapping,
  AccountInfo,
  KpiMappings,
} from "@blisspointmedia/bpm-types/dist/KpiMapping";
import {
  Button,
  ButtonType,
  Dropdown,
  DropdownToggleType,
  OverlayTrigger,
  Tooltip,
} from "../Components";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { formatNumberAsInt } from "../utils/format-utils";

const LAG_OPTIONS = {
  tvad: [
    { label: "1d", value: "1" },
    { label: "3d", value: "3" },
    { label: "7d", value: "7" },
    { label: "14d", value: "14" },
    { label: "30d", value: "30" },
  ],
  reddit: [
    { label: "1d", value: "1" },
    { label: "7d", value: "7" },
    { label: "30d", value: "30" },
  ],
  criteo: [
    { label: "1d", value: "1" },
    { label: "7d", value: "7" },
    { label: "30d", value: "30" },
  ],
};

interface HandleOnChangeProps {
  source: string;
  crossChannelKpiId: string;
  isCrossChannelKpiLabel: boolean;
  key: string;
  mappings: SourceMapping[];
  accountId: string;
  isNewRow?: boolean;
  isClearMapping?: boolean;
}

const getValuesToUse = (
  accountIdSourceMappings: SourceMapping[],
  key: string,
  editsMap: KpiEdits
) => {
  if (R.has(key, editsMap)) {
    return R.pathOr([], [key], editsMap).map((edit: SourceMapping) => ({
      label: edit.kpi,
      value: edit.kpi,
    }));
  } else {
    return accountIdSourceMappings.map(mapping => ({ label: mapping.kpi, value: mapping.kpi }));
  }
};

interface SourceColumnProps {
  combinedKpiMappings: any;
  source: string;
  crossChannelKpis: CombinedCrossChannelKpis;
  editsMap: KpiEdits;
  newRows: KpiMappings;
  accounts: AccountInfo[];
  lagEdits: Record<string, string>;
  handleKpiEdit: (input: HandleKpiEditInput) => void;
  handleNewRow: (input: HandleNewRowInput) => void;
  handleLagEdit: (input: HandleLagEditInput) => void;
  handleDeleteMapping: (input: HandleDeleteMappingInput) => void;
}
const SourceColumn: React.FC<SourceColumnProps> = React.memo(
  ({
    combinedKpiMappings,
    source,
    crossChannelKpis,
    editsMap,
    newRows,
    accounts,
    lagEdits,
    handleKpiEdit,
    handleNewRow,
    handleLagEdit,
    handleDeleteMapping,
  }) => {
    const {
      sourceIdMap,
      kpiOptions,
      editMode,
      sourceToPrettyName,
      rawSourceKpiCounts,
      lags,
    } = useContext(KpiMappingsContext);

    const [collapsed, setCollapsed] = useState(false);

    const handleOnChange = useCallback(
      ({
        source,
        crossChannelKpiId,
        isCrossChannelKpiLabel,
        isNewRow,
        key,
        mappings,
        accountId,
        isClearMapping,
      }: HandleOnChangeProps) => {
        if (isNewRow) {
          handleNewRow({
            crossChannelKpiId,
            kpiLabel: "",
            sourceLabel: source,
            isCrossChannelKpiLabel,
            sourceMappings: mappings,
            accountId,
          });
        } else if (isClearMapping) {
          handleDeleteMapping({
            key,
            source,
            crossChannelKpiId,
            accountId,
          });
        } else {
          handleKpiEdit({
            key,
            mappings,
          });
        }
      },
      [handleKpiEdit, handleNewRow, handleDeleteMapping]
    );

    let accountsWithFill = accounts;
    // For cases like TVAD, account ID will be null so this will be empty but we need a placeholder
    if (accounts.length === 0) {
      //@ts-ignore
      accountsWithFill = [{ accountId: null, accountName: null }];
    }

    /**
     * Apply the selected KPI(s) to all accounts for the given source
     */
    const applyKpiToAllAccounts = useCallback(
      ({ accountIdSourceMappings, editsKey, crossChannelKpiId, accountId, isNewRow }) => {
        if (isNewRow) {
          // Get new row mappings for source
          const newKpis: SourceMapping[] = R.pathOr(
            [],
            [crossChannelKpiId, "mappings", source],
            newRows
          );

          // Get new row mappings for the account that you are extending from
          const newKpisForAccount = newKpis.filter(kpi => kpi.accountId === accountId);

          // For each account for this source, apply the same KPIs to all of them
          accounts.forEach(account => {
            const edits = newKpisForAccount.map((edit: SourceMapping) => ({
              ...edit,
              accountId: account.accountId,
            }));
            handleOnChange({
              source,
              crossChannelKpiId,
              isNewRow,
              isCrossChannelKpiLabel: false,
              key: makeEditKey({ crossChannelKpiId, source, accountId: account.accountId }),
              mappings: edits,
              accountId: account.accountId,
            });
          });
        } else {
          if (!R.has(editsKey, editsMap) && R.isEmpty(accountIdSourceMappings)) {
            return;
          }

          // Use either the pending edits or the existing mappings
          const mappingsToUse = editsMap[editsKey] || accountIdSourceMappings;

          // Apply mappings to all accounts for this source
          accounts.forEach(account => {
            handleOnChange({
              source,
              crossChannelKpiId,
              isNewRow,
              isCrossChannelKpiLabel: false,
              key: makeEditKey({ crossChannelKpiId, source, accountId: account.accountId }),
              mappings: mappingsToUse,
              accountId: account.accountId,
            });
          });
        }
      },
      [accounts, editsMap, handleOnChange, source, newRows]
    );

    const selectOnChange = useCallback(
      ({ newVals, source, crossChannelKpiId, accountId, editsKey, isNewRow }) => {
        let mappings: SourceMapping[] = [];
        if (R.isNil(newVals)) {
          handleOnChange({
            source,
            crossChannelKpiId,
            isCrossChannelKpiLabel: false,
            key: editsKey,
            mappings: [],
            accountId,
            isClearMapping: true,
          });
        } else {
          mappings = newVals.map(val => ({
            kpi: val.value,
            crossChannelKpiId,
            source,
            sourceId: sourceIdMap[source],
            accountId,
          }));
        }

        handleOnChange({
          source,
          crossChannelKpiId,
          isNewRow,
          isCrossChannelKpiLabel: false,
          key: editsKey,
          mappings: mappings,
          accountId,
        });
      },
      [handleOnChange, sourceIdMap]
    );

    const getKpiCounts = useCallback(
      (valuesToUse: { label: string; value: string }[], accountId: string) => {
        if (R.isEmpty(valuesToUse)) {
          return 0;
        }

        let total = 0;
        for (let { value } of valuesToUse) {
          const count = R.pathOr(0, [source, accountId, value], rawSourceKpiCounts);
          total += count;
        }

        return total;
      },
      [rawSourceKpiCounts, source]
    );

    const lagToUse = useMemo(() => lagEdits[source] || lags[source] || "Not set", [
      lagEdits,
      lags,
      source,
    ]);

    return (
      <div className="sourceColumn">
        <div className="columnHeader">
          <div>{sourceToPrettyName[source] || source}</div>
          {LAG_OPTIONS[source] &&
            (editMode ? (
              <div className="lagDropdown">
                <Dropdown
                  type={DropdownToggleType.OUTLINED}
                  label="Lag"
                  value={lagToUse}
                  options={LAG_OPTIONS[source]}
                  onChange={lag => handleLagEdit({ source, lag })}
                />
              </div>
            ) : (
              <div style={{ fontSize: "14px", fontWeight: 400 }}>{`Lag: ${
                lagToUse === "Not set" ? lagToUse : `${lagToUse}d`
              }`}</div>
            ))}
          {accountsWithFill.length > 1 && (
            <Button
              size="sm"
              style={{ height: "18px" }}
              type={ButtonType.EMPTY}
              onClick={() => setCollapsed(prev => !prev)}
              icon={collapsed ? <MdArrowForwardIos /> : <MdArrowBackIosNew />}
            />
          )}
        </div>
        <div className="accountIdColumns">
          {collapsed ? (
            <div style={{ minWidth: "200px", marginTop: "32px" }}>
              Expand to see raw KPIs for all {sourceToPrettyName[source] || source} accounts.
            </div>
          ) : (
            accountsWithFill.map((accountInfo, i) => {
              const { accountId, accountName } = accountInfo;
              return (
                <div className="accountIdColumn" key={accountId}>
                  <div className="accountIdLabel">
                    <div className="labelPrefix">Account:</div>
                    <div className="labelValue">{accountName || accountId || "N/A"}</div>
                  </div>
                  {crossChannelKpis.map(({ crossChannelKpiLabel, crossChannelKpiId, isNewRow }) => {
                    // If this is existing row, the key is the crossChannelKpiLabel. If it's new row, the key is the crossChannelKpiId (which is a UUID)
                    const kpiMappingsKey = isNewRow ? crossChannelKpiId : crossChannelKpiLabel;

                    const sourceMappings = R.pathOr(
                      [] as SourceMapping[],
                      [kpiMappingsKey, "mappings", source],
                      combinedKpiMappings
                    );

                    const accountIdSourceMappings = R.filter(
                      R.propEq("accountId", accountId),
                      sourceMappings
                    );

                    const editsKey = makeEditKey({ crossChannelKpiId, source, accountId });

                    const valuesToUse = getValuesToUse(accountIdSourceMappings, editsKey, editsMap);

                    const kpiCounts = getKpiCounts(valuesToUse, accountId);

                    return (
                      <div key={crossChannelKpiId} className="sourceItem">
                        <div className="sourceKpiPicker">
                          {i === 0 && accountsWithFill.length > 1 && editMode && (
                            <OverlayTrigger
                              placement={OverlayTrigger.PLACEMENTS.BOTTOM.CENTER}
                              delay={500}
                              overlay={
                                <Tooltip>
                                  Apply selected KPI(s) to all accounts for{" "}
                                  {sourceToPrettyName[source]}
                                </Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                style={{ height: "38px", marginRight: "5px" }}
                                varant={ButtonFrameworkVariant.ICON_ONLY}
                                type={ButtonType.OUTLINED}
                                onClick={() =>
                                  applyKpiToAllAccounts({
                                    accountIdSourceMappings,
                                    editsKey,
                                    crossChannelKpiId,
                                    accountId,
                                    isNewRow,
                                  })
                                }
                              >
                                <MdDoubleArrow />
                              </Button>
                            </OverlayTrigger>
                          )}
                          <div className="sourceKpiDropdown">
                            <Select
                              isDisabled={!editMode}
                              className="kpiSelectorDropdown"
                              placeholder="Choose Raw KPI(s)"
                              isClearable
                              isMulti
                              options={(kpiOptions[source] || []).map(option => {
                                return { label: option, value: option };
                              })}
                              value={valuesToUse}
                              onChange={(newVals: { label: string; value: string }[]) =>
                                selectOnChange({
                                  newVals,
                                  source,
                                  crossChannelKpiId,
                                  accountId,
                                  editsKey,
                                  isNewRow,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="rawSourceKpiCount">
                          {!R.isEmpty(valuesToUse) && (
                            <>
                              <div>KPI Volume:</div>
                              <div>{formatNumberAsInt(kpiCounts)}</div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
);

export default SourceColumn;
