import "./SegmentationLabeling.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import Papa from "papaparse";
import SegmentationLabelingTable from "./SegmentationLabelingTable";
import { CampaignRow } from "@blisspointmedia/bpm-types/dist/CampaignLabelingTool";
import {
  awaitJSON,
  CrossChannelLambdaFetch,
  MiscLambdaFetch,
  pollS3,
  SegmentationMappingLambdaFetch,
} from "../utils/fetch-utils";
import { useCompanyInfo } from "../redux/company";
import { useSetError } from "../redux/modals";
import { RouteComponentProps } from "@reach/router";
import {
  Button,
  ButtonType,
  FilterBar,
  FullPageSpinner,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "../Components";
import { useStateFunction } from "../utils/hooks/useData";
import PendingSegmentationChanges from "./PendingSegmentationChanges";
import { CustomSegmentsData } from "./SegmentationMapping";
import IncompleteUpdatesModal from "./IncompleteUpdatesModal";
import pako from "pako";
import { Nav } from "react-bootstrap";
import { MdDelete, MdOutlineAvTimer, MdSave, MdSend } from "react-icons/md";
import { ButtonFrameworkVariant } from "../Components/ButtonFramework";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";

const LABELED_EDITS_STORAGE_KEY = "labeledCustomSegmentsEdits";
const UNLABELED_EDITS_STORAGE_KEY = "unlabeledCustomSegmentsEdits";

export interface SegmentRow extends CampaignRow {
  ad_group_id?: string;
  ad_group_name?: string;
  ad_id?: string;
  ad_name?: string;
  ingested_seg_id?: string;
  pg_seg_id?: string;
  Channel?: string;
  Platform?: string;
  channel_id?: number;
  platform_id?: number;
}

export interface SegmentEditsMap {
  [campaign_id: string]: SegmentRow;
}

interface SegmentationLabelingProps {
  data: any[] | null;
  customSegments: CustomSegmentsData[] | undefined;
  fetchRawData: () => void;
  dataGranularity: "ad" | "ad_group" | "campaign";
}

enum TabOptions {
  LABELED = "Labeled",
  UNLABELED = "Unlabeled",
}

interface UpdatedRow {
  campaign_id: string;
  ad_group_id?: string;
  ad_id?: string;
  segmentMap: Record<string, number>;
}

const saveToLocalStorage = (key, data) => {
  try {
    const compressed = Papa.unparse([data]);
    localStorage.setItem(key, compressed);
  } catch (e) {
    console.error(`Error saving to local storage ${key}:`, e);
  }
};

const SegmentationLabeling = ({
  data,
  customSegments,
  fetchRawData,
  dataGranularity,
}: SegmentationLabelingProps & RouteComponentProps): JSX.Element => {
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [labeledEditsMap, setLabeledEditsMap] = useState<SegmentEditsMap>({});
  const [unlabeledEditsMap, setUnlabeledEditsMap] = useState<SegmentEditsMap>({});
  const [selectedRows, setSelectedRows] = useState<Record<string, SegmentRow>>({});
  const [activeTab, setActiveTab] = useState<string>(TabOptions.UNLABELED);
  const [showIncompleteUpdatesModal, setShowIncompleteUpdatesModal] = useState(false);
  const [cacheRetrieved, setCacheRetrieved] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!cacheRetrieved) {
      const cachedLabeledEdits = localStorage.getItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
      if (cachedLabeledEdits) {
        try {
          const parsedCachedLabeledEdits = Papa.parse(cachedLabeledEdits)[0];
          setLabeledEditsMap(prevlabeledEditsMap => {
            return { ...parsedCachedLabeledEdits, ...prevlabeledEditsMap };
          });
        } catch (e) {
          localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
          console.error("Error parsing cached labeled edits:", e);
        }
      }

      const cachedUnlabeledEdits = localStorage.getItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
      if (cachedUnlabeledEdits) {
        try {
          const parsedCachedUnlabeledEdits = Papa.parse(cachedUnlabeledEdits)[0];
          setUnlabeledEditsMap(prevUnlabeledEditsMap => {
            return { ...parsedCachedUnlabeledEdits, ...prevUnlabeledEditsMap };
          });
        } catch (e) {
          localStorage.removeItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
          console.error("Error parsing cached unlabeled edits:", e);
        }
      }

      setCacheRetrieved(true);
    }
  }, [cacheRetrieved, cid]);

  useEffect(() => {
    if (Object.keys(labeledEditsMap).length > 0) {
      saveToLocalStorage(`${cid}_${LABELED_EDITS_STORAGE_KEY}`, labeledEditsMap);
    }
  }, [cid, labeledEditsMap]);

  useEffect(() => {
    if (Object.keys(unlabeledEditsMap).length > 0) {
      saveToLocalStorage(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`, unlabeledEditsMap);
    }
  }, [cid, unlabeledEditsMap]);

  const filterBarOptions = useMemo(() => {
    const uniqueSegmentOptions =
      customSegments?.map(segment => ({
        name: segment.segmentName,
        label: segment.segmentName,
      })) ?? [];

    const options = [
      ...uniqueSegmentOptions,
      { name: "account_id", label: "Account ID" },
      { name: "account_name", label: "Account Name" },
      { name: "campaign_id", label: "Campaign ID" },
      { name: "campaign_name", label: "Campaign" },
    ];

    if (dataGranularity === "ad_group" || dataGranularity === "ad") {
      options.push({ name: "ad_group_id", label: "Ad Group ID" });
      options.push({ name: "ad_group_name", label: "Ad Group" });
    }

    if (dataGranularity === "ad") {
      options.push({ name: "ad_id", label: "Ad ID" });
      options.push({ name: "ad_name", label: "Ad" });
    }
    return options;
  }, [customSegments, dataGranularity]);

  const unlabeledData = useMemo(() => {
    return data?.filter(row => !(row.pg_seg_id || row.ingested_seg_id));
  }, [data]);

  const labeledData = useMemo(() => {
    return data?.filter(row => row.pg_seg_id || row.ingested_seg_id);
  }, [data]);

  const incompleteEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledEditsMapCopy = { ...unlabeledEditsMap };
    const segmentKeys = Object.keys(unlabeledEditsMap);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMapCopy[key];

      let hasAllSegments = true;
      for (const customSegment of customSegments) {
        hasAllSegments = hasAllSegments && value.edits && value.edits[customSegment.segmentName];
      }

      if (hasAllSegments) {
        delete unlabeledEditsMapCopy[key];
      }
    }

    return unlabeledEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const completeUnlabeledEditsMap = useMemo(() => {
    if (!customSegments) {
      return {};
    }

    const unlabeledSegmentEditsMapCopy = { ...unlabeledEditsMap };
    const segmentKeys = Object.keys(unlabeledEditsMap);

    for (const key of segmentKeys) {
      const value = unlabeledEditsMap[key];

      let hasAllSegments = true;
      for (const customSegment of customSegments) {
        hasAllSegments = hasAllSegments && value.edits && value.edits[customSegment.segmentName];
      }

      if (!hasAllSegments) {
        delete unlabeledSegmentEditsMapCopy[key];
      }
    }

    return unlabeledSegmentEditsMapCopy;
  }, [customSegments, unlabeledEditsMap]);

  const dataToUse = useMemo(() => {
    return activeTab === TabOptions.UNLABELED
      ? { data: unlabeledData, editsMap: unlabeledEditsMap, editsMapSetter: setUnlabeledEditsMap }
      : { data: labeledData, editsMap: labeledEditsMap, editsMapSetter: setLabeledEditsMap };
  }, [labeledData, labeledEditsMap, activeTab, unlabeledData, unlabeledEditsMap]);

  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);

  const filteredData = useMemo(() => {
    return dataToUse.data?.filter(filter) || [];
  }, [filter, dataToUse]);

  const [showPendingChanges, setShowPendingChanges] = useState<boolean>(false);
  const hasPendingChanges = useMemo(() => {
    return !R.isEmpty(unlabeledEditsMap) || !R.isEmpty(labeledEditsMap);
  }, [labeledEditsMap, unlabeledEditsMap]);

  const clearAllChanges = () => {
    setUnlabeledEditsMap({});
    setLabeledEditsMap({});
    localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
    localStorage.removeItem(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`);
    setShowPendingChanges(false);
  };

  const [saving, setSaving] = useState(false);

  const save = useCallback(async () => {
    if (!customSegments) {
      return;
    }

    setSaving(true);

    try {
      const combinedValues = [
        ...Object.values(completeUnlabeledEditsMap ?? {}),
        ...Object.values(labeledEditsMap),
      ];
      const updatedRows = combinedValues.map(edit => {
        const segmentMap: Record<string, number> = {};
        customSegments.forEach(segment => {
          if (!edit.edits) {
            return;
          }

          segmentMap[segment.segmentId] = edit.edits[segment.segmentName]?.value;
        });

        return {
          ad_id: edit.ad_id,
          ad_group_id: edit.ad_group_id,
          campaign_id: edit.campaign_id,
          segmentMap,
        };
      });

      const sendToLambda = async (rows: UpdatedRow[]) => {
        const compressedPayload = pako.gzip(JSON.stringify(rows), { to: "string" });
        const base64Payload = btoa(compressedPayload);

        if (process.env.NODE_ENV === "development") {
          console.info(
            "In development mode, posting updateManualSegmentationMaps to non-comet endpoint"
          );
          const body = {
            company: cid,
            updatedRows: base64Payload,
          };
          await SegmentationMappingLambdaFetch("/updateManualSegmentationMaps", {
            method: "POST",
            body,
          });
        } else {
          const lambdaArgs = {
            company: cid,
            updatedRows: base64Payload,
          };

          const result = await MiscLambdaFetch("/kickOffLambda", {
            method: "POST",
            body: {
              fileType: "txt",
              lambdaArgs,
              lambdaName: "segmentationmapping-updateManualSegmentationMaps",
            },
          });

          const uuid = await result.json();
          const content = await pollS3({
            autoDownload: false,
            bucket: "bpm-cache",
            filename: `${uuid}.txt`,
            mimeType: "text/plain",
          });
          const textContent = await content.text();

          if (result.status !== 200) {
            throw new Error(textContent);
          }
        }
      };

      const batchRows: UpdatedRow[] = [];
      let byteSizeNum = 0;
      for (const updatedRow of updatedRows) {
        const compressedPayload = pako.gzip(JSON.stringify(updatedRow), { to: "string" });
        const base64Payload = btoa(compressedPayload);
        const byteSize = new TextEncoder().encode(base64Payload).length;
        if (byteSizeNum + byteSize > 155000 && batchRows.length > 0) {
          await sendToLambda(batchRows);
          batchRows.length = 0;
          byteSizeNum = 0;
        }

        batchRows.push(updatedRow);
        byteSizeNum += byteSize;
      }

      if (batchRows.length > 0) {
        await sendToLambda(batchRows);
      }

      await fetchRawData();
      setUnlabeledEditsMap(incompleteEditsMap);
      setLabeledEditsMap({});
      setSelectedRows({});

      localStorage.removeItem(`${cid}_${LABELED_EDITS_STORAGE_KEY}`);
      saveToLocalStorage(`${cid}_${UNLABELED_EDITS_STORAGE_KEY}`, incompleteEditsMap);
      setSaving(false);
    } catch (e) {
      setSaving(false);
      const reportError = e as Error;
      setError({ message: reportError.message, reportError });
    }
  }, [
    customSegments,
    completeUnlabeledEditsMap,
    labeledEditsMap,
    fetchRawData,
    incompleteEditsMap,
    cid,
    setError,
  ]);

  const validChangesCount = useMemo(() => {
    return Object.keys(completeUnlabeledEditsMap).length + Object.keys(labeledEditsMap).length;
  }, [completeUnlabeledEditsMap, labeledEditsMap]);

  const refreshCrossChannel = useCallback(() => {
    (async () => {
      try {
        setRefreshing(true);
        Mixpanel.track(MxE.REFRESH_CC_VIEWS);
        const res = await CrossChannelLambdaFetch("/refreshCrossChannelViews", {
          method: "POST",
          body: { company: cid },
        });
        const resJson = await awaitJSON(res);
        const { message, status } = resJson;
        setRefreshing(false);
        setError({
          variant: status === "IN_PROGRESS" ? "warning" : "success",
          title: status === "IN_PROGRESS" ? "Publish already in progress" : "Publish started",
          message: <div>{message}</div>,
        });
      } catch (e: any) {
        setRefreshing(false);
        setError({
          message: `Failed to publish to dashboards for ${cid}. Error: ${e.message}`,
          reportError: e,
        });
      }
    })();
  }, [cid, setError]);

  return (
    <div className="segmentationLabeling">
      <IncompleteUpdatesModal
        show={showIncompleteUpdatesModal}
        handleClose={() => setShowIncompleteUpdatesModal(false)}
        saveChanges={save}
        incompleteEditsMap={incompleteEditsMap}
        customSegments={customSegments}
        validChangesCount={validChangesCount}
        dataGranularity={dataGranularity}
      />
      <Nav
        activeKey={activeTab}
        onSelect={value => {
          const valueTab = value as keyof typeof value;

          if (!valueTab || valueTab === activeTab) {
            return;
          }

          setActiveTab(valueTab);
          setSelectedRows({});
        }}
      >
        <Nav.Item key={TabOptions.UNLABELED}>
          <Nav.Link eventKey={TabOptions.UNLABELED}>{TabOptions.UNLABELED}</Nav.Link>
        </Nav.Item>
        <Nav.Item key={TabOptions.LABELED}>
          <Nav.Link eventKey={TabOptions.LABELED}>{TabOptions.LABELED}</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="segmentationLabelingBody">
        {dataToUse.data ? (
          <>
            <div className="filterBarContainer">
              <FilterBar
                options={filterBarOptions}
                lines={dataToUse.data || []}
                onFilter={setFilter}
                hasAdvanced
              />
              <div className="pendingChangesControls">
                <Button
                  type={ButtonType.FILLED}
                  design="secondary"
                  variant={ButtonFrameworkVariant.LEADING_ICON}
                  icon={<MdOutlineAvTimer />}
                  onClick={() => {
                    setShowPendingChanges(R.not);
                  }}
                  disabled={!hasPendingChanges}
                >
                  View Pending Changes
                </Button>
                <OverlayTrigger
                  placement={OverlayTrigger.PLACEMENTS.TOP.RIGHT}
                  delay={500}
                  overlay={
                    <Tooltip>
                      {
                        "Publish the latest mapping changes to dashboards now.\nChanges will auto-update by the next day if not manually published"
                      }
                    </Tooltip>
                  }
                >
                  <Button
                    type={ButtonType.OUTLINED}
                    variant={ButtonFrameworkVariant.LEADING_ICON}
                    icon={<MdSend color="#6B2DEF" />}
                    onClick={() => refreshCrossChannel()}
                  >
                    {refreshing ? <Spinner /> : "Publish to dashboards"}
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
            <SegmentationLabelingTable
              data={filteredData}
              customSegments={customSegments}
              editsMap={dataToUse.editsMap}
              selectedRows={selectedRows}
              setEditsMap={dataToUse.editsMapSetter}
              setSelectedRows={setSelectedRows}
              dataGranularity={dataGranularity}
            />
          </>
        ) : (
          <FullPageSpinner />
        )}
        <div className="segmentationLabelingControls">
          <div className="pendingChangesControls">
            <Button
              type={ButtonType.OUTLINED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              onClick={clearAllChanges}
              disabled={!hasPendingChanges}
              icon={<MdDelete color="#6B2DEF" />}
            >
              Discard Changes
            </Button>
            <Button
              type={ButtonType.FILLED}
              variant={ButtonFrameworkVariant.LEADING_ICON}
              onClick={
                Object.keys(incompleteEditsMap).length > 0
                  ? () => {
                      setShowIncompleteUpdatesModal(true);
                    }
                  : save
              }
              disabled={saving || !hasPendingChanges}
              icon={<MdSave />}
            >
              {saving ? <Spinner /> : "Save"}
            </Button>
          </div>
        </div>
        {showPendingChanges && hasPendingChanges && (
          <PendingSegmentationChanges
            incompleteUnlabeledEditsMap={incompleteEditsMap}
            completeUnlabeledEditsMap={completeUnlabeledEditsMap}
            labeledEditsMap={labeledEditsMap}
            setUnlabeledEditsMap={setUnlabeledEditsMap}
            setMappedSegmentEditsMap={setLabeledEditsMap}
            setShowPendingChanges={setShowPendingChanges}
            customSegments={customSegments}
            dataGranularity={dataGranularity}
          />
        )}
      </div>
    </div>
  );
};

export default SegmentationLabeling;
