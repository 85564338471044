import * as R from "ramda";
import * as Dfns from "date-fns/fp";
import * as DfnsTZ from "date-fns-tz/fp";
import {
  DateGroupingKey,
  DATE_FORMAT,
  DATE_GROUPING_OPTIONS,
  DAY_DATE_LABEL,
  MONTH_DATE_LABEL,
  QUARTER_DATE_LABEL,
  WEEK_DATE_LABEL,
  YEAR_DATE_LABEL,
  DAY_DATE_TOOLTIP_LABEL,
  MONTH_DATE_TOOLTIP_LABEL,
  METRIC_TO_PRETTY_NAME,
  DateGroupingOption,
} from "./homePageConstants";
import { formatMoney, formatNumber, formatNumberAsInt } from "../utils/format-utils";
import { PerformanceData } from "@blisspointmedia/bpm-types/dist/MetricsTable";

export const getGroupingValue = (date: string, groupingKey: DateGroupingKey): any => {
  const parsedDate = Dfns.parseISO(date);
  switch (groupingKey) {
    case "Day":
      return date;
    case "Week":
      return Dfns.format(DATE_FORMAT, Dfns.startOfISOWeek(parsedDate));
    case "Month":
      return Dfns.format(DATE_FORMAT, Dfns.startOfMonth(parsedDate));
    case "Quarter":
      return Dfns.format(DATE_FORMAT, Dfns.startOfQuarter(parsedDate));
    case "Year":
      return Dfns.format(DATE_FORMAT, Dfns.startOfYear(parsedDate));
    default:
      throw new Error(`Invalid grouping key: ${groupingKey}`);
  }
};

export const formatDateLabel = (date: string, groupingKey = "Day", isTooltip = false): string => {
  if (!date) {
    return "";
  }

  let format = "";
  switch (groupingKey) {
    case "Day":
      format = isTooltip ? DAY_DATE_TOOLTIP_LABEL : DAY_DATE_LABEL;
      break;
    case "Week":
      format = isTooltip ? DAY_DATE_TOOLTIP_LABEL : WEEK_DATE_LABEL;
      break;
    case "Month":
      format = isTooltip ? MONTH_DATE_TOOLTIP_LABEL : MONTH_DATE_LABEL;
      break;
    case "Quarter":
      format = QUARTER_DATE_LABEL;
      break;
    case "Year":
      format = YEAR_DATE_LABEL;
      break;
    default:
      format = DAY_DATE_LABEL;
  }

  const toUTC = DfnsTZ.utcToZonedTime("UTC", new Date(date));
  const isValid = Dfns.isValid(toUTC);

  if (!isValid) {
    return "";
  }

  const formattedDate = Dfns.format(format, toUTC);

  if (groupingKey === "Week") {
    return `w/o ${formattedDate}`;
  }

  return formattedDate;
};

/**
 * Return the date grouping options that are relevant based on the provided date range.
 * For example, if the date range spans 3 weeks we don't want to provide the option to group by
 * month, quarter, or year.
 */
export const getDateGroupingOptions = (start: string, end: string): DateGroupingOption[] => {
  const DATE_GROUPING_TO_DAYS = {
    Day: 1,
    Week: 7,
    Month: 31,
    Quarter: 89,
    Year: 366,
  };

  const daysDiff = Dfns.differenceInDays(Dfns.parseISO(start), Dfns.parseISO(end));
  return DATE_GROUPING_OPTIONS.filter(option => daysDiff > DATE_GROUPING_TO_DAYS[option.value]);
};

// TODO: Move these to a more appropriate location
// Metric formatters
export const VOLUME_FORMATTER = (value: number): string =>
  value > 0 && value < 1 ? "<1" : formatNumberAsInt(value);
export const ROAS_FORMATTER = (value: number): string =>
  value > 0 && value < 0.01 ? "<0.01" : formatNumber(value, 2);
export const CPX_FORMATTER = (value: number): string =>
  value > 0 && value < 0.01 ? "<$0.01" : formatMoney(value, value < 15 ? 2 : 0);
export const CPM_FORMATTER = (value: number): string => formatMoney(value, 2);
export const IMP_FORMATTER = (value: number): string => formatNumberAsInt(value);
export const SPEND_FORMATTER = (value: number): string =>
  value > 0 && value < 1 ? "<$1" : formatMoney(value, 0);
export const REVENUE_FORMATTER = (value: number): string =>
  value > 0 && value < 1 ? "<$1" : formatMoney(value, 0);
export const RELATIVE_FORMATTER = (value: number): string =>
  `${Math.round(value).toLocaleString()}%`;
export const PERCENTAGE_FORMATTER = (value: number, decimals = 0): string =>
  `${formatNumber(value * 100, decimals)}%`;
export const CTR_FORMATTER = (value: number): string =>
  `${formatNumber(value * 100, 2).toLocaleString()}%`;

export const SPEND_IN_THOUSANDS_FORMATTER = (value: number): string =>
  value > 0 && value < 1 ? "<$1" : formatMoney(value / 1000, 0);
export const IMP_IN_THOUSANDS_FORMATTER = (value: number): string =>
  formatNumberAsInt(value / 1000);
// formatValue receives parameters from metricsTableUtils.ts which we are utilizing here to determine special formatting for Streaming Video.
const CLICKS_FORMATTER = (value: number, _: number, row: PerformanceData): string => {
  if (R.path(["dimensions", "Channel"], row) === "Streaming Video") {
    return "--";
  }
  return value > 0 && value < 1 ? "<1" : formatNumberAsInt(value);
};

export const METRIC_TO_FORMATTER = {
  acos: PERCENTAGE_FORMATTER,
  aov: REVENUE_FORMATTER,
  clicks: CLICKS_FORMATTER,
  conversion_rate: (value: number): string => PERCENTAGE_FORMATTER(value, 2),
  conversionRate: (value: number): string => PERCENTAGE_FORMATTER(value, 2),
  cpc: CPM_FORMATTER,
  cpm: CPM_FORMATTER,
  cpx: CPX_FORMATTER,
  ctr: CTR_FORMATTER,
  imps: IMP_FORMATTER,
  percentage: PERCENTAGE_FORMATTER,
  relative: RELATIVE_FORMATTER,
  revenue: REVENUE_FORMATTER,
  roas: ROAS_FORMATTER,
  spend: SPEND_FORMATTER,
  volume: VOLUME_FORMATTER,
};

export const metricFormatter = (metric: string): ((value: number) => string) => {
  if (METRIC_TO_FORMATTER[metric]) {
    return METRIC_TO_FORMATTER[metric];
  } else {
    return METRIC_TO_FORMATTER.volume;
  }
};

export const getPrettyMetricName = (metric: string): string => {
  return METRIC_TO_PRETTY_NAME[metric] || metric;
};

/**
 * Used to get the company name from a KPI. Pretty much just relevant for "global" companies.
 * E.g. company is instacartglobal but KPI is instacart_visits_filtered, so we use instacart as the company.
 */
export const getBrandFromKpi = (kpi: string): string | undefined => {
  let kpiCompanyMatch = kpi.match(/^([^_]+)_/);
  if (kpiCompanyMatch && kpiCompanyMatch.length >= 2) {
    return kpiCompanyMatch[1];
  }
  return undefined;
};
