import { Response } from "@blisspointmedia/bpm-types/dist/Onboarding";
import { FormLayoutProps } from "../OnboardingFreeForm";
import { PlatformOptions } from "../PlatformChecklist/PlatformChecklistOptions";

const PLATFORM_ACCESS_COLUMNS = [
  {
    title: "primary",
    type: "header",
    width: 1,
  },
  {
    title: "Access Guide",
    type: "custom",
    width: 1,
    required: false,
  },
  {
    title: "Access Granted",
    type: "checkbox",
    width: 1,
    required: false
  },
  {
    title: "Country",
    type: "select",
    width: 1,
    required: true,
    selectOptions: ["USA", "Canada"]
  },
  {
    title: "Account Name",
    type: "text",
    width: 2,
    required: true,
    textPlaceholder: "Account Name"
  },
  {
    title: "Account ID",
    type: "text",
    width: 1,
    required: true,
    textPlaceholder: "Account ID"
  },
  {
    title: "Account Owner",
    type: "select",
    width: 2,
    required: true,
    selectOptions: ["Self", "Tinuiti"]
  },
  {
    title: "Notes / Security Steps",
    type: "text",
    width: 2,
    required: false,
    textPlaceholder: "Notes / Security Steps"
  },
  // {
  //   title: "Add Account",
  //   type: "button",
  //   width: 1
  // }
];

const platformAccessForm = (responses: Response[], prevId: string, id: string) => {
  const currentSectionSelections = responses.filter(response => response.questionNumber.startsWith(id));
  const previousSectionSelections = responses.filter(response => response.questionNumber.startsWith(prevId));

  const selections = currentSectionSelections.length > 0 ? currentSectionSelections : previousSectionSelections;

  const rows = selections.length > 0
    ? selections.map((platform, index) => ({
        id: index.toString(),
        questionNumber: `${id}-${index + 1}`,
        title: platform.additionalInfo || "Untitled Platform",
        children: <a href="https://www.google.com">Access Guide</a>
      }))
    : [];

  return {
    columns: PLATFORM_ACCESS_COLUMNS,
    rows: rows,
  } as FormLayoutProps;
};

export const platformAccessOptions = (selectedPlatforms: Response[]): PlatformOptions => {
  return {
    "sourceOfTruth": {
      simpleId: "2Source",
      title: "Source Of Truth*",
      table: platformAccessForm(selectedPlatforms, "1Source", "2Source")
    },
    "affiliateLifecycle": {
      simpleId: "2Affiliate",
      title: "Affiliate, Lifecycle",
      table: platformAccessForm(selectedPlatforms, "1Affiliate", "2Affiliate")
    },
    "marketplaces": {
      simpleId: "2Marketplaces",
      title: "Marketplaces",
      table: platformAccessForm(selectedPlatforms, "1Marketplace", "2Marketplaces")
    },
    "programmaticDisplay": {
      simpleId: "2Programmatic",
      title: "Programmatic, Display, OTT, Streaming",
      table: platformAccessForm(selectedPlatforms, "1Programmatic", "2Programmatic")
    },
    "searchShopping": {
      simpleId: "2Search",
      title: "Search & Shopping",
      table: platformAccessForm(selectedPlatforms, "1Search", "2Search")
    },
    "seoCro": {
      simpleId: "2SEO",
      title: "SEO, CRO",
      table: platformAccessForm(selectedPlatforms, "1SEO", "2SEO")
    },
    "social": {
      simpleId: "2Social",
      title: "Social",
      table: platformAccessForm(selectedPlatforms, "1Social", "2Social")
    },
    "misc": {
      simpleId: "2Misc",
      title: "Misc",
      table: platformAccessForm(selectedPlatforms, "1Misc", "2Misc")
    }
  };
};
