import { DimensionColumn } from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  Metric,
  Metrics,
  CrossChannelMetrics,
  MetricTotalsByDate,
} from "@blisspointmedia/bpm-types/dist/CrossChannel";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import {
  CDN,
  CellData,
  ColumnMetaData,
  DimensionMap,
  toPretty1000sInteger,
  toPrettyPercent,
} from "../SingleChannel/MetricsTable/metricsTableUtils";
import { METRIC_TO_PRETTY_NAME } from "./crossChannelConstants";
import * as Dfns from "date-fns/fp";
import * as DfnsTZ from "date-fns-tz/fp";
import * as R from "ramda";
import { metricFormatter } from "./crossChannelFormatters";

const DAY_DATE_LABEL = "M/dd/yy";
const formatDateLabel = (date: string): string => {
  if (!date) {
    return "";
  }

  const toUTC = DfnsTZ.utcToZonedTime("UTC", new Date(date));
  const isValid = Dfns.isValid(toUTC);

  if (!isValid) {
    return "";
  }

  const formattedDate = Dfns.format(DAY_DATE_LABEL, toUTC);

  return formattedDate;
};

export const makePrettyDateRange = (start: string, end: string): string => {
  return `${formatDateLabel(start)}–${formatDateLabel(end)}`;
};

/**
 * Get default date ranges for date picker.
 */
export const getDefaultDates = (): {
  start: string;
  end: string;
  otherStart: string;
  otherEnd: string;
} => {
  // TODO: Remove after demos.
  const start = "2024-04-01";
  const end = "2024-04-30";
  const otherStart = "2024-03-01";
  const otherEnd = "2024-03-30";
  // const start = R.pipe(Dfns.subDays(31), Dfns.format(DATE_FORMAT))(new Date());
  // const end = R.pipe(Dfns.subDays(1), Dfns.format(DATE_FORMAT))(new Date());
  // const otherStart = R.pipe(Dfns.subDays(61), Dfns.format(DATE_FORMAT))(new Date());
  // const otherEnd = R.pipe(Dfns.subDays(32), Dfns.format(DATE_FORMAT))(new Date());
  return { start, end, otherStart, otherEnd };
};

/**
 * Aggregate platform delivery metrics by day for the Overview tab.
 *
 * We don't want to display performance metrics in the Overview Spark Charts and Compare Metrics chart.
 * The KPI Volume and Funnel Dynamics charts will show performance metrics from a Source of Truth.
 */
export const calculateOverviewDeliveryMetricsByDay = (
  metricsData: CrossChannelMetrics[]
): MetricTotalsByDate[] => {
  if (R.isNil(metricsData) || R.isEmpty(metricsData)) {
    return [];
  }

  // Filter out performance metrics
  const deliveryMetricsData = metricsData.map(({ date, metrics }) => {
    return {
      date,
      metrics: {
        [Metric.CLICKS]: metrics[Metric.CLICKS],
        [Metric.CPC]: metrics[Metric.CPC],
        [Metric.CPM]: metrics[Metric.CPM],
        [Metric.CTR]: metrics[Metric.CTR],
        [Metric.IMPRESSIONS]: metrics[Metric.IMPRESSIONS],
        [Metric.SPEND]: metrics[Metric.SPEND],
      },
    };
  });

  // Sum metric totals by day
  let metricsByDay: Record<string, Metrics> = {};
  for (let item of deliveryMetricsData) {
    const { date, metrics } = item;
    metricsByDay[date] = R.mergeWith(R.add, metricsByDay[date] || {}, metrics);
  }

  // Calculate derived metrics by day
  const allMetricsByDay = Object.entries(metricsByDay).map(([date, metrics]) => {
    const spend = R.pathOr(0, [date, Metric.SPEND], metricsByDay);
    const clicks = R.pathOr(0, [date, Metric.CLICKS], metricsByDay);
    const impressions = R.pathOr(0, [date, Metric.IMPRESSIONS], metricsByDay);

    let computedMetrics = {};
    computedMetrics[Metric.CPC] = calculateCPC(spend, clicks);
    computedMetrics[Metric.CPM] = calculateCPM(spend, impressions);
    computedMetrics[Metric.CTR] = calculateCTR(clicks, impressions);

    return { date, metrics: { ...metrics, ...computedMetrics } };
  });

  return allMetricsByDay.sort((a, b) => a.date.localeCompare(b.date));
};

/**
 * Calculate Cost-Per-Click (CPC).
 */
export const calculateCPC = (spend: number, clicks: number): number => {
  return clicks ? spend / clicks : 0;
};

/**
 * Calculate Cost-Per-Thousand Impressions (CPM).
 */
const calculateCPM = (spend: number, impressions: number): number => {
  return impressions ? (spend / impressions) * 1000 : 0;
};

/**
 * Calculate Cost-Per-X. X is the selected KPI (CPX)
 */
export const calculateCPX = (spend: number, volume: number): number => {
  return volume ? spend / volume : 0;
};

/**
 * Calculate Return on Ad Spend (ROAS)
 */
export const calculateROAS = (revenue: number, spend: number): number => {
  return spend ? revenue / spend : 0;
};

/**
 * Calculate Click Through Rate (CTR)
 */
export const calculateCTR = (clicks: number, impressions: number): number => {
  return impressions ? clicks / impressions : 0;
};

/**
 * Calculate Conversion Rate (CR)
 */
export const calculateCR = (volume: number, impressions: number): number => {
  return impressions ? volume / impressions : 0;
};

export const getPrettyMetricName = (metric: string): string => {
  return METRIC_TO_PRETTY_NAME[metric] || metric;
};

export const makeColumnMetaData = (
  metric: CC.DimensionColumnType,
  iconStyle?: "logo" | "thumbnail" | undefined
): ColumnMetaData => {
  return {
    contentType: "text",
    dimensionVarName: metric,
    displayName: metric,
    iconStyle: iconStyle || undefined,
  };
};

export const dimensionColumnMetaDataMap: Partial<Record<CC.DimensionColumnType, ColumnMetaData>> = {
  Platform: makeColumnMetaData("Platform", "logo"),
  Channel: makeColumnMetaData("Channel", "logo"),
  "Account Name": makeColumnMetaData("Account Name"),
  "Account ID": makeColumnMetaData("Account ID"),
};

export const columnMetaDataMap: Partial<Record<CC.ColumnType, ColumnMetaData>> = {
  [Metric.CLICKS]: {
    displayName: "Clicks",
    formatValue: metricFormatter(Metric.CLICKS),
  },
  [Metric.SPEND]: {
    displayName: "Spend",
    formatValue: metricFormatter(Metric.SPEND),
  },
  [Metric.IMPRESSIONS]: {
    displayName: "Impressions (000s)",
    formatValue: toPretty1000sInteger,
  },
  [Metric.VOLUME]: {
    displayName: "KPI Volume",
    formatValue: metricFormatter(Metric.VOLUME),
  },
  [Metric.REVENUE]: {
    displayName: "Revenue",
    formatValue: metricFormatter(Metric.REVENUE),
  },
  [Metric.CPC]: {
    displayName: "CPC",
    formatValue: metricFormatter(Metric.CPC),
    aggregator: agg =>
      agg[Metric.CLICKS] && agg[Metric.SPEND]
        ? (agg[Metric.SPEND] as number) / (agg[Metric.CLICKS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.CLICKS] && fetch[Metric.SPEND]
        ? (fetch[Metric.SPEND] as number) / (fetch[Metric.CLICKS] as number)
        : "--",
    requiredTotalsColumns: [Metric.CLICKS, Metric.SPEND],
  },
  [Metric.CPM]: {
    displayName: "CPM",
    formatValue: metricFormatter(Metric.CPM),
    aggregator: agg =>
      agg[Metric.IMPRESSIONS] && agg[Metric.SPEND]
        ? ((agg[Metric.SPEND] as number) * 1000) / (agg[Metric.IMPRESSIONS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.IMPRESSIONS] && fetch[Metric.SPEND]
        ? ((fetch[Metric.SPEND] as number) * 1000) / (fetch[Metric.IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [Metric.IMPRESSIONS, Metric.SPEND],
  },
  [Metric.ROAS]: {
    displayName: "ROAS",
    formatValue: metricFormatter(Metric.ROAS),
    aggregator: agg =>
      agg[Metric.REVENUE] && agg[Metric.SPEND]
        ? (agg[Metric.REVENUE] as number) / (agg[Metric.SPEND] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.REVENUE] && fetch[Metric.SPEND]
        ? (fetch[Metric.REVENUE] as number) / (fetch[Metric.SPEND] as number)
        : 0,
    requiredTotalsColumns: [Metric.REVENUE, Metric.SPEND],
  },
  [Metric.CPX]: {
    displayName: "CPX",
    formatValue: metricFormatter(Metric.CPX),
    aggregator: agg =>
      agg[Metric.VOLUME] && agg[Metric.SPEND]
        ? (agg[Metric.SPEND] as number) / (agg[Metric.VOLUME] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.VOLUME] && fetch[Metric.SPEND]
        ? (fetch[Metric.SPEND] as number) / (fetch[Metric.VOLUME] as number)
        : "--",
    requiredTotalsColumns: [Metric.VOLUME, Metric.SPEND],
  },
  [Metric.CTR]: {
    displayName: "CTR",
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg[Metric.CLICKS] && agg[Metric.IMPRESSIONS]
        ? (agg[Metric.CLICKS] as number) / (agg[Metric.IMPRESSIONS] as number)
        : "--",
    fetchGetter: fetch =>
      fetch[Metric.CLICKS] && fetch[Metric.IMPRESSIONS]
        ? (fetch[Metric.CLICKS] as number) / (fetch[Metric.IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [Metric.CLICKS, Metric.IMPRESSIONS],
  },
  [Metric.CONVERSION_RATE]: {
    displayName: "Conversion Rate",
    formatValue: toPrettyPercent,
    aggregator: agg =>
      agg[Metric.VOLUME] && agg[Metric.IMPRESSIONS]
        ? (agg[Metric.VOLUME] as number) / (agg[Metric.IMPRESSIONS] as number)
        : "--",
    decimals: 2,
    fetchGetter: fetch =>
      fetch[Metric.VOLUME] && fetch[Metric.IMPRESSIONS]
        ? (fetch[Metric.VOLUME] as number) / (fetch[Metric.IMPRESSIONS] as number)
        : "--",
    requiredTotalsColumns: [Metric.VOLUME, Metric.IMPRESSIONS],
  },
};

export const getCrossChannelDimensionCell = (
  dimensionData: DimensionMap,
  dimensionHeader: DimensionColumn
): CellData => {
  const { dimensionVarName, dimensionTypeName, icon } = dimensionHeader;
  const resolvedDimensionData = dimensionData as Record<string, string>;
  const dimensionValue = resolvedDimensionData[dimensionVarName];
  const defaultCell = {
    label: dimensionValue,
    value: dimensionValue,
  };
  if (dimensionTypeName === "Channel") {
    const parsedDimensionValue = dimensionValue.replace(/\s+/g, "");
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/channels/${parsedDimensionValue}.png`
      : undefined;
    return {
      ...defaultCell,
      url,
    };
  } else if (dimensionTypeName === "Platform") {
    const channelMap = {
      audio: "Streaming Audio",
      linear: "Linear TV",
      streaming: "Streaming Video",
    };
    const prettyNameMap = {
      audio: "Streaming Audio",
      facebook: "Meta",
      google_ads: "Google Ads",
      linear: "Linear TV",
      streaming: "Streaming Video",
      tiktok: "TikTok",
      twitter: "X",
    };
    const prettyName = prettyNameMap[dimensionValue.toLowerCase()]
      ? prettyNameMap[dimensionValue.toLowerCase()]
      : `${dimensionValue.replace(/_/g, " ").charAt(0).toUpperCase()}${dimensionValue
          .replace(/_/g, " ")
          .slice(1)}`;
    const url = !R.isNil(icon)
      ? `${CDN}/assets/img/${
          channelMap[dimensionValue.toLowerCase()] ? "channels" : "platforms"
        }/${prettyName.replace(/ /g, "")}.png`
      : undefined;
    return {
      label: prettyName,
      value: prettyName,
      url,
    };
  }

  return defaultCell;
};
